<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Details for {{collector}}: {{collectorDetails?.id}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <pre style="border-style: inset">{{getJsonHtml(collectorDetails)}}</pre>
  </div>
  <div class="modal-footer">
    <div class="col text-center">
      <button class="btn btn-primary" type="button" (click)="activeModal.close('Close click')">Close</button>
    </div>
  </div>
</div>
