import { Component, Input } from '@angular/core';
import { CHART_DEFAULT_COLORS } from '../../app-utility';
import { IChartData } from '../chart-interfaces';

@Component({
  selector: 'app-vertical-bar-chart-ngx',
  templateUrl: './vertical-bar-chart-ngx.component.html',
  styleUrls: ['./vertical-bar-chart-ngx.component.scss']
})
export class VerticalBarChartNgxComponent {
  @Input() title: string;
  @Input() data: IChartData;
  @Input() colorScheme: any;
  @Input() xAxisLabel: string = '';
  @Input() yAxisLabel: string = '';
  DEFAULT_CHART_COLORS: any = { domain: [...CHART_DEFAULT_COLORS]};
}
