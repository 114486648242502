import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-prime',
  templateUrl: './table-prime.component.html',
  styleUrls: ['./table-prime.component.scss']
})

export class TablePrimeComponent extends ChartComponent implements OnInit  {
    private tableData: any;
    @Input()
      set detailData(data:any) {
        if(data[0] && data[0].status?.length){
          this.tableData = data[0];
          this.handleDataChanges();
        }
    }


  @Output() selectedColumnsChange = new EventEmitter<any[]>();

 // selectedColumns: any[] = []; // Initially selected columns
 
  onColumnsChange() {
    this.selectedColumnsChange.emit(this._selectedColumns);
    this.flag=2;
  }


  col1:any=[];
  flag=0;
    handleDataChanges() {
      this.ownerStatus=[];
     
    this.tableData.ownerStatus.forEach(Username => {
      let x: any[] = Object.entries(Username)[0];
      let y = Object.entries(x[1])[0];
      this.cols=[];
     // this.ownerStatus=[];
      let obj: any = {};
      obj.Name = x[0];
  
      
      this.tableData.status.forEach(ss => {
          obj[ss] = x[1][ss];
      });
    
      obj.Total = x[1].Total;
      obj.Estimates = x[1].Estimates;
      this.ownerStatus.push(obj);
    });
      
    
    this.tableData.status.forEach(stat=>{
    this.cols.push({field:stat,header:stat});
    

    });

      this.cols = [
         ...this.cols,
      ];
      this._selectedColumns = this.cols;
      if(this.flag==0){ 
        this.col1=this.cols;
        
      }
      this.flag=1;
    }


    ownerStatus:any=[];

    cols: any=[];

    _selectedColumns: any[];


    constructor(private modalService: NgbModal) {
        super();
    }

    mySortingFunction = (a, b): number => {
         let aa:number=parseInt(a.key);
        let bb:number=+b.key
         return aa > bb ? 1 : (bb > aa ? -1 : 0);
       }
      
   ngOnInit() {
    }




    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        this._selectedColumns = val;
    }


}
