<div class="radial-chart-view">
  <span class="title" *ngIf="title">{{title}}</span>
  <div class="chart-container" *ngIf="data && data.series?.length else msg_block">
    <apx-chart
      [series]="data.series"
      [labels]="data.labels"
      [chart]="chartOptions"
      [plotOptions]="plotOptions"
      [colors]="colorScheme"
    ></apx-chart>
    <div class="item-list-view">
      <span class="title" *ngIf="data.listTitle">{{data.listTitle}}</span>
      <div class="list-view">
        <div class="item-row" *ngFor="let item of data.items; let i=index">
          <div class="color-circle" [ngStyle]="{backgroundColor: colorScheme[i]}"></div>
          <span class="label-small" [title]="item.name">
            <a href={{item.url}} target="_blank">{{item.name}} - {{data.series[i]}}<span *ngIf="!data.hidePercentageSign">%</span></a></span>
        </div>
      </div>
    </div>
  </div>
  <ng-template #msg_block>
    <span class="msg_block">{{data?.loadingMessage}}</span>
  </ng-template>
</div>