import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-number-card-chart-static-analysis',
  templateUrl: './number-card-chart-static-analysis.component.html',
  styleUrls: ['./number-card-chart-static-analysis.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NumberCardChartStaticAnalysisComponent extends ChartComponent {

  constructor() {
    super();
    this.scaleFactor = .85;
  }

  // options
  textColor = 'white';
  valueFormatting: (val: number) => string = this.formatInteger;
  // view: number[];

  formatInteger(val: number): string {
    return val.toFixed(0);
  }

  onSelect(event){
    let object = this.data.filter(a => event.label === a.name)
    var url ="";
    if(object && object.length>0){
      url = object[0].sonarLink;
    }
    window.open(url, '_blank');
  }
}
