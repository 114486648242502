<div class="template-item" *ngFor="let widget of editedWidgets;" [ngClass]="[widget.title[0]== 'Feature' ? 'feature':'',  widget.fullWidth ? 'full-width-view' : '']">
  <div class="template-item" *ngIf="widget.component.length > 1; else elseblock" id="{{widget.component}}">
    <nb-card>
      <nb-tabset>
        <nb-tab *ngFor="let component of widget.component; let i = index;" tabTitle="{{widget.title[i]}}"
                [active]="i === 0">
          <nb-card>
            <app-widget-header
              [title]="widget.title[i]"
              [widgetType]="component"
              [status]="widget.status"
              [configForm]="widget.configForm[i]"
              [deleteForm]="widget.deleteForm[i]"
              [scoreData]="getScoreResult(widget.title[i])"
              [scoreEnable]="scoreEnable"
              >
            </app-widget-header>
          </nb-card>
        </nb-tab>
      </nb-tabset>
    </nb-card>
  </div>
  <ng-template #elseblock>
    <nb-card>
      <app-widget-header
        [title]="widget.title[0]"
        [widgetType]="widget.component[0]"
        [status]="widget.status"
        [configForm]="widget.configForm[0]"
        [deleteForm]="widget.deleteForm[0]"
        [scoreData]="getScoreResult(widget.title[0])"
        [scoreEnable]="scoreEnable"
        >
        
      </app-widget-header>
    </nb-card>
  </ng-template>
</div>
