import { Component, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailModalComponent } from '../../modals/detail-modal/detail-modal.component';
import { ChartComponent } from '../chart/chart.component';
import {ILineChartData} from './line-chart-interfaces';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LineChartComponent extends ChartComponent {
  
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  tooltipDisabled = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  timeline = false;
  maxYAxisTickLength = 8;
  maxXAxisTickLength = 8;
  yAxisTickFormatting: (val: number) => string = this.formatInteger;
  xAxisTickFormatting: (val: Date) => string = this.formatToDayAndMonth;

  constructor(private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
  }

  formatInteger(val: number): string {
    if (Number.isInteger(val)) {
      return val.toFixed(0);
    }
    return '';
  }

  formatToDayAndMonth(val: Date): string {
    return (val?.getMonth() + 1) + '/' + val?.getDate();
  }

  openDetailView(isPR = 0){
    if(isPR == 2){
      const modalRef = this.modalService.open(DetailModalComponent,{ size: 'mysize' });
      modalRef.componentInstance.title = `Top Contributors`;
     
      modalRef.componentInstance.detailData = {componentId : this.data.componentId, showCount: true};
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
    let filterBy = isPR ? "Pulls" : "Commits" 
    if (isPR != 2 && this.data && (this.data as ILineChartData).detailComponent) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = `${isPR ? "Pull Request": "Commit"} Details`;
      const dataset =  this.data.dataPoints.find(i => i.name ===  filterBy );
      let allData = []
      dataset.series.forEach(i => {
        allData = [...allData, ...i.data]
      });
      modalRef.componentInstance.detailData = {data: allData, componentId : this.data.componentId};
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
  }

  onSelect(event) {
    if (this.data && (this.data as ILineChartData).detailComponent) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = (event && event.series) ? event.series : 'Details';
      const dataset = this.data.dataPoints.find(i => i.name === event.series);
      modalRef.componentInstance.detailData = dataset.series.find(i => i.name === event.name);
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
  }
}