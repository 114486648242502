import { Component,Inject } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import * as FullStory from '@fullstory/browser';
import { environment } from 'src/environments/environment';
import {UserDataService}  from 'src/app/core/services/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  title = 'DPS Engineering';

  constructor(public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public customerDataService : UserDataService) {

    // load the FullStory recording snippet if an orgId is set
    if (environment.fullStoryOrgId !== "") {
       FullStory.init({ orgId:environment.fullStoryOrgId, debug: false});
    }
  }

  async login() {
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }

  async logout() {
    await this.oktaAuth.signOut();
  }
  
}
