import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-bordered',
  templateUrl: './table-bordered.component.html',
  styleUrls: ['./table-bordered.component.scss']
})
export class TableBorderedComponent implements OnInit{
  @Input() repoData: any;
  @Input() previousMonths: any;

  constructor() {}

  ngOnInit(): void {
  }
}
