<div *ngIf="template; then template_container; else content_container"></div>
<ng-template #template_container>
  <div *ngIf="active">
    <ng-container [ngTemplateOutlet]="template.templateRef"></ng-container>
  </div>
</ng-template>
<ng-template #content_container>
  <div [hidden]="!active">
    <ng-content></ng-content>
  </div>
</ng-template>
<ng-template #labelIsStringTmpl>{{ label }}</ng-template>
