<nb-layout>
  <nb-layout-header>
    <app-navbar></app-navbar>
  </nb-layout-header>
  <nb-layout-column>
    <div id="container">
      <router-outlet></router-outlet>
    </div>
  </nb-layout-column>
</nb-layout>
