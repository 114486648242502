import { Component, Input, OnInit } from '@angular/core';
import { IDetailsHeaderViewData } from '../../charts/chart-interfaces';
import { PROJECT_LATEST_SNAPSHOT_DATA, SONAR_CODE_SUMMARY } from '../../app-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-details-header-view',
  templateUrl: './details-header-view.component.html',
  styleUrls: ['./details-header-view.component.scss']
})
export class DetailsHeaderViewComponent implements OnInit{
  @Input() data:IDetailsHeaderViewData;
  @Input() title:string = SONAR_CODE_SUMMARY;
  projectLatestSnapshotData: string = "";


  constructor(public activeModal: NgbActiveModal){
    
  }
  ngOnInit(): void {
    if(this.data.projectNameWithTimeStamp.length > 0) {
      this.projectLatestSnapshotData = PROJECT_LATEST_SNAPSHOT_DATA;
      this.data.projectNameWithTimeStamp.forEach((projectDetails, i) => {
        if(i % 3 == 0) {
          this.projectLatestSnapshotData += "\n" + projectDetails.projectName + " : " + projectDetails.timestamp + ", ";
        } else {
          this.projectLatestSnapshotData += projectDetails.projectName + " : " + projectDetails.timestamp;
          if (i % 3 == 1) this.projectLatestSnapshotData += ", "
        }
      });
      if(this.data.projectNameWithTimeStamp.length % 3 != 0) {
        this.projectLatestSnapshotData = this.projectLatestSnapshotData.substring(0, this.projectLatestSnapshotData.length - 2);
      }
    }
  }

}
