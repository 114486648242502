import { Component, OnInit} from '@angular/core';
import { ChartComponent } from '../chart/chart.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRotationData } from '../rotation/rotation-chart-interfaces';
import { DetailModalComponent } from '../../modals/detail-modal/detail-modal.component';
import { cloneDeep } from '../../app-utility';

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent extends ChartComponent implements OnInit {
  single: any[];
  onBarClick: boolean ;

  constructor(private modalService: NgbModal) { 
    super()
  }
  
  ngOnInit(): void {
    if (this.data && this.data.items) {
      const statusCount = {};     
      this.data.items[0][1].rotationData.forEach(item => {
        const status = item.progressStatus;
        if (!statusCount[status]) {
          statusCount[status] = 1;
        } else {
          statusCount[status]++;
        }
      });
      const customOrder = ['New','Approved', 'Active','In Progress','Validating','Committed','Done', 'Completed', 'Closed','Removed' ];
      this.single = customOrder.map(status => ({
        name: status,
        value: statusCount[status] || 0
      }))
      .filter(status => status.value > 0);  
    } else {
      this.single = []; 
  } 
}
  view: any[] = [800, 600];

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true; 
  showYAxisLabel = true;
  
  d:any={}
  fd:any=[]
  demo: any=this.data
  onSelect(event) {
    if (this.data && (this.data as IRotationData).clickableContent) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = 'Story Details';
      this.d = cloneDeep(this.data);   
      this.fd = this.data.items[0][1].rotationData.filter(data=>data.progressStatus==event.name); 
      this.d.items[0][1].storiesData = cloneDeep(this.fd);
      modalRef.componentInstance.detailData = this.d.items[0]; 
      modalRef.componentInstance.onBarClick=true;
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.clickableContent;
    }
  }
}

