import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChartComponent} from "../chart/chart.component";
import {DetailModalComponent} from "../../modals/detail-modal/detail-modal.component";
import {IBarChartData} from "../vertical-bar-chart-generic/vertical-bar-chart-interfaces";

@Component({
  selector: 'app-stacked-barchart',
  templateUrl: './stacked-barchart.component.html',
  styleUrls: ['./stacked-barchart.component.scss']
})
export class StackedBarchartComponent extends ChartComponent implements OnInit {

  constructor(private modalService: NgbModal) {
    super();
  }

  /**
   * Properties of the horizontal bar chart
   */
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = true;
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public legendTitle: string = "Bugs"
  public severityBugsAPIResponse: any[];
  yAxisTicks:number[];

  ngOnInit(): void {
    if (this.data.dataPoints) {
      let chartMaxValue = 0;
      this.severityBugsAPIResponse = Object.keys(this.data.dataPoints).map((name, i) => {
        const series = Object.keys(this.data.dataPoints[name])?.map((status) => {
          if(this.data.dataPoints[name][status]?.length>chartMaxValue){
            chartMaxValue = this.data.dataPoints[name][status]?.length;
          } 
          return {
            name: status,
            value: this.data.dataPoints[name][status]?.length
          };
        });
        return {
          name,
          series
        };
      });
      this.yAxisTicks = chartMaxValue<5 ? Array.from(Array(chartMaxValue+1).keys()) : undefined;   
    }
  }
  

  onSelect(event) {
    type BugList = Array<any>;
    if (this.data && (this.data as IBarChartData).detailComponent) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = 'Bugs Details';
      let flatBugs: BugList = Object.values(this.data.dataPoints).flatMap((severityGroup) =>
        Object.values(severityGroup).flatMap((statusGroup) => statusGroup)
      );
      modalRef.componentInstance.detailData = flatBugs.filter(b => (b.severity == event.series && b.status == event.name));
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
  }

  yAxisFormatter(val){
    return Number(val).toFixed(0);
  }

}
