<div class="product-widget-template-view">
  <nb-tabset (changeTab)="onTabSelection($event)">
      <nb-tab *ngFor="let widget of widgets; let i = index"
        tabTitle="{{widget.title}}"
        [active]="i===0"
      >
        <app-widget-view-renderer
          [title]="widget.title"
          [widgetView]="widget.component"
          [status]="widget.status"
        />
      </nb-tab>
  </nb-tabset>    
</div>