<ngx-gauge 
[size]="size"
[type]="type"
[thick]="thick"
[value]="value"
[cap]="cap"
[label]="label"
[append]="gaugeAppendText"
[prepend]="gaugePrependText"
[foregroundColor]="foregroundColor"
[backgroundColor]="backgroundColor"
[max]="max"
></ngx-gauge>
