import { IChart } from 'src/app/shared/interfaces';
import {RepoDetailComponent} from '../repo-detail/repo-detail.component';
import {PlainTextChartComponent} from '../../../shared/charts/plain-text-chart/plain-text-chart.component';
import { VerticalBarChartComponent } from 'src/app/shared/charts/vertical-bar-chart/vertical-bar-chart.component';
import { IBarChartData } from 'src/app/shared/charts/vertical-bar-chart/vertical-bar-chart-interfaces';

export const REPO_CHARTS: IChart[] = [
  {
    // title: 'Issues, Pulls and Commits Per Day',
    title: 'Pulls and Commits Per Day',
    component: VerticalBarChartComponent,
    data: {
      verticalBarChart: true,
      detailComponent: RepoDetailComponent,
      dataPoints: [
        {
          name: 'Commits',
          series: []
        },
        {
          name: 'Pulls',
          series: []
        },
        {
          name: 'Issues',
          series: []
        },
      ]} as IBarChartData,
    xAxisLabel: 'Date',
    // yAxisLabel: 'Commits, Pull Requests, and Issues',
    yAxisLabel: 'Commits, Pull Requests',
    colorScheme: {
      domain: ['blue', 'green', 'red']
    }
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
  {
    title: '',
    component: PlainTextChartComponent,
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    colorScheme: {}
  },
];
