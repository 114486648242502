import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-prime-panel-menu',
  templateUrl: './prime-panel-menu.component.html',
  styleUrls: ['./prime-panel-menu.component.scss']
})
export class PrimePanelMenuComponent {
  @Input() menuData: MenuItem[];

}
