import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { LayoutComponent } from '../layout/layout.component';
import { ChartDirective } from '../../charts/chart.directive';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-cards-layout',
  templateUrl: './dynamic-cards-layout.component.html',
  styleUrls: ['./dynamic-cards-layout.component.scss']
})
export class DynamicCardsLayoutComponent extends LayoutComponent  implements AfterViewInit {
  
  @ViewChildren(ChartDirective) childChartTags: QueryList<ChartDirective>;

  @ViewChildren('chartParent') chartContainers: QueryList<ElementRef>;

  constructor(componentFactoryResolver: ComponentFactoryResolver, cdr: ChangeDetectorRef) {
    super(componentFactoryResolver, cdr);
  }

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  // Initialize charts and resize hook
  ngAfterViewInit() {
    super.loadComponent(this.childChartTags);
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(50)).subscribe(_ => {
      super.resize(this.chartContainers.toArray());
    });
    super.resize(this.chartContainers.toArray());
  }
}
