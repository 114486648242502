<app-tabs #tabs>
  <app-tab #tab1 [label]="'Tab 1'" [active]="true">
    Tab 1 Contents
  </app-tab>
  <app-tab #tab2 [label]="'Tab 2'" [active]="true">
    Tab 2 Content
  </app-tab>
  <app-tab #tab3 [label]="'Tab 3'" [disabled]="true">
    Tab 3 Content, Disabled
  </app-tab>
  <app-tab #tab4 [label]="'Tab 4'">
    Tab 4 Content
  </app-tab>
</app-tabs>
