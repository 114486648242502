  <div>
    {{title}}
    </div>
<ngx-charts-bar-vertical
  [view]="view"
  [results]="single"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical>
