import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import Chart from "chart.js/auto";
import { IStackedDoughnutChartData } from "../chart-interfaces";

@Component({
  selector: "app-stacked-doughnut-chart",
  templateUrl: "./stacked-doughnut-chart.component.html",
  styleUrls: ["./stacked-doughnut-chart.component.scss"],
})
export class StackedDoughnutChartComponent implements OnChanges {
  @Input() data: IStackedDoughnutChartData;
  chartData: any;
  chartOptions: any;
  constructor() {}

  ngOnChanges(): void {
    if (this.data) {
      this.getPieChart();
    }
  }

  getPieChart() {
    this.chartData = {
      labels: this.data?.labels,
      datasets: this.data?.datasets,
    };

    // this.chartData = this.data;
    this.chartOptions = {
      plugins: {
        tooltip : {
          callbacks: {
            label: function(context) {
                let label = context?.label +' - '+context?.formattedValue + context?.dataset?.symbol;
                return label;
            }
        } 
        },
        legend: {
          //to set dynamic position for legends
          position: this.data?.legendPosition || "right",
          //click event for legends to execute
          onClick: (evt, legendItem, legend) => {
            const datasetBackground =
              evt?.chart?.data?.datasets[0]?.backgroundColor;
            const selectedIndex = datasetBackground.indexOf(
              legendItem.fillStyle
            );

            const allDatasetData = evt?.chart?.data?.datasets[0]?.data;
            const allDatasetURL = evt?.chart?.data?.datasets[0]?.url;

            if (allDatasetURL?.length === allDatasetData?.length) {
              window.open(allDatasetURL[selectedIndex], "_blank");
            }
          },
          onHover: (event) => {
            event.native.target.style.cursor = "pointer";
          },
          onLeave: (event) => {
            event.native.target.style.cursor = "default";
          },

          labels: {
            usePointStyle: true,
            pointStyle: this.data?.pointStyle || "circle",
            generateLabels: (chart) => {
              let visibility = [];
              for (let i = 0; i < chart.data.datasets.length; i++) {
                if (!chart.isDatasetVisible(i)) {
                  visibility.push(true);
                } else {
                  visibility.push(false);
                }
              }
              return chart.data?.labels.map((doughnut, index) => ({
                //for customized labels or default labels
                text:
                  chart.data.datasets[0]?.legendLabels?.length > 0
                    ? chart.data.datasets[0]?.legendLabels[index]
                    : doughnut + " - " + chart.data.datasets[0]?.data[index],
                fillStyle: chart.data.datasets[0]?.backgroundColor[index],
                hidden: visibility[index],
              }));
            },
          },
        },
      },
    };
  }
}
