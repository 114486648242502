<div class="widget dark-chart-theme" #linearGaugecontainerRef>
  <div class="chart-heading">
    {{title}}
  </div>
  <ng-container *ngIf="!data?.linearGauge else elseBlock">
    <ngx-charts-gauge
    [view]="[linearGaugecontainerRef.offsetWidth, 300]"      
      [results]="data?.dataPoints"
      [scheme] = "colorScheme"
      [min]="data?.min"
      [max]="data?.max"
      [angleSpan]="180"
      [startAngle]="-90"
      [showAxis]="false"
      [bigSegments]="10"
      [smallSegments]="5"
      [units]= "data?.units"
      [valueFormatting]="formatting"
      (select)="onSelect($event)">
    </ngx-charts-gauge>
  </ng-container>
  <ng-template #elseBlock>
    <ngx-charts-linear-gauge
      [view]="[linearGaugecontainerRef.offsetWidth, 300]"        
      [scheme]="colorScheme"
      [value]="data?.dataPoints"
      [min]="data?.min"
      [max]="data?.max"
      [units]="data?.units"
      [valueFormatting]="formatting">
    </ngx-charts-linear-gauge>
  </ng-template>
</div>
