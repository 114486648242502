import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartComponent } from '../chart/chart.component';
import { IBarChartData } from './vertical-bar-chart-interfaces';
import { DetailModalComponent } from '../../modals/detail-modal/detail-modal.component';
import { FIFTEEN_STRING, SIXTY_STRING, THIRTY_STRING } from '../../app-constants';

@Component({
  selector: 'app-vertical-bar-chart-generic',
  templateUrl: './vertical-bar-chart-generic.component.html',
  styleUrls: ['./vertical-bar-chart-generic.component.scss']
})

export class VerticalBarChartGenericComponent extends ChartComponent {

  private vericalBarChartData: any[] = [];
  bugsData: any[] = [];
  displayMessages: any[] = [];

  constructor(private modalService: NgbModal) {
    super();
  }
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  tooltipDisabled = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  timeline = false;
  noBarWhenZero = true;
  maxYAxisTickLength = 8;
  maxXAxisTickLength = 8;
  adoBoardValue: string = "";
    
  ngOnInit(): void {
    this.initializeDataState();
    this.initializeGraphDateSet();  
  }

  private initializeDataState(){
    this.adoBoardValue = this.data.adoBoard;
    this.displayMessages = this.data.displayMessages;
    this.data.dataPoints.map(el => {
      if (el.name === 'Open' || el.name === 'Closed') {
        el.series.map(ele => {
          this.vericalBarChartData.push(ele.name);
        });
      }
    });
    const getDate = dateString => {
      const [day, month, year] = dateString.split(' ')[0].split('/');
      return new Date(`${year}-${month}-${day}`).getTime();
    };
    if(this.data.selectDays === FIFTEEN_STRING || this.data.selectDays === THIRTY_STRING || this.data.selectDays === SIXTY_STRING) {
      this.vericalBarChartData.sort((a, b) => {
        const dateA = getDate(a);
        const dateB = getDate(b);
        return dateA - dateB;
      });
    }
  }

  private initializeGraphDateSet(){
      this.xAxisLabel = this.data.xAxisLabel;

      this.vericalBarChartData.forEach(element => {
        let openBugsValue;
        let closedBugsValue;
        this.data.dataPoints.map(ele => {
          if (ele.name === 'Open') {
            ele.series.map(currDate => {
              if (currDate.name === element) {
                openBugsValue = currDate.value;
              }
            });
          }
          if (ele.name === 'Closed') {
            ele.series.map(currDate => {
              if (currDate.name === element) {
                closedBugsValue = currDate.value;
              }
            });
          }
        });
        this.bugsData.push({
          name: element,
          series: [
            {
              name: 'Open',
              value: (openBugsValue === undefined ? 0 : openBugsValue)
            },
            {
              name: 'Closed',
              value: (closedBugsValue === undefined ? 0 : closedBugsValue)
            }
          ]
        });
      });
      this.data.displayMessages.forEach(display => {
        if(display.title.indexOf("Open") != -1) {
          display.count = this.getBugsCount('Open');
        } else {
          display.count = this.getBugsCount('Closed'); 
        }
      })
  }

  getBugsCount(openOrClosed: string): number {
    let count = 0;
    this.data.dataPoints.filter(d => d.name === openOrClosed).forEach(d => d.series.forEach(s => count += s.value));
    return count;
  }



  onSelect(event): void {
    if (this.data && (this.data as IBarChartData).detailComponent){
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = this.data.title || event?.label || 'Details';
      const dataset = this.data.dataPoints.find(i => i.name === event.label);
      modalRef.componentInstance.detailData = dataset.series.find(i => i.name === event.series);
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
  }

  yAxisFormatter(val){
    return Number(val).toFixed(0);
  }
}
