<div class=" single-chart  text-center" *ngIf="data; else msg_block">
  <svg viewBox="0 0 36 36" class="circular-chart " [ngClass]="data.color === 'purple' ? 'purple': 'blue' " [pTooltip]="title?title:''">
    <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path class="circle" [attr.stroke-dasharray]="data.percentage ? data.percentage : 0" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
    <text x="18" y="20.35" class="percentage"
      *ngIf="data?.chartData || data?.chartData === 0">{{data?.chartData}}</text>
  </svg>

  <div class="b text-center title" *ngIf="data?.subTitle" placement="bottom" [ngbTooltip]=" title?title:'' ">{{data?.subTitle}}
  </div>

</div>
<ng-template #msg_block>
  <span class="msg_block text-center">{{data?.loadingMessage}}</span>
</ng-template>