import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITableData } from 'src/app/shared/tables/table-view/table-view-interface';
import { API_PARAM_PAGE, API_PARAM_SIZE, NO_DATA_AVAILABLE, REPO, SE_METRICS, HYPHEN, NAN, DASHBOARD_EMD, DASHBOARD_CCT, DASHBOARD_Admin_Portal, DASHBOARD_AppRuntime, DASHBOARD_First_Mile_Customer_Support, DASHBOARD_Global_Design_System, DASHBOARD_Notifications_Framework, DASHBOARD_Receipt_Scanning, DASHBOARD_PSD, DSX_PROGRAM, SINGLE_QUOTES, DASHBOARD_D2C, DASHBOARD_DTV, DASHBOARD_DX, DASHBOARD_Virtual_Control_Room, PROGRAM, NAME, DASHBOARD_WALMART_LUMINATE, CONSUMER, SUPPLY_CHAIN, DSX, SUPPLY_CHAIN_TITLE, CONSUMER_TITLE, DSX_TITLE, COMMERCIAL_AND_CONSUMER_TITLE, COMMERCIAL_AND_CONSUMER } from '../shared/app-constants';
import { Observable, Subscription, of } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { DashboardListService } from '../landing_page/dashboard-list/dashboard-list.service';
import { IDashboards } from '../landing_page/dashboard-list/dashboard-list';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CloudCostService } from '../widget_modules/cloud-metric/cloud-metric.services';
import { formatDate } from '@angular/common';
import { ProductDataService } from 'src/app/product_widget_modules/product-data.service';
import moment from 'moment';
import { getMonthName, getMonthOptions } from '../shared/app-utility';


@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss']
})


export class CostComponent implements OnInit, OnDestroy {
  monthOptions$: Observable<{ value: number, label: string }[]>;
  selectedMonth: any;
  month: number;
  year: number;
  noDataAvailable: boolean;
  dsxKpisNumbers = {};
  dsxRowData = {
    'repo': {
      name: DSX_PROGRAM,
      period: HYPHEN,
      id: HYPHEN
    }
  }
  public SeMetricsMessage = SE_METRICS;
  public noDataMessage = NO_DATA_AVAILABLE;
  public productDashboards: IDashboards[] = [];


  payload: any = {}

  selectedTab: string = 'tabSeMetrics';
  rangeDates: Date[] = [];
  categoryProgramList: { title: string, data: ITableData }[] = [
    { title: COMMERCIAL_AND_CONSUMER_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
    { title: SUPPLY_CHAIN_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
    { title: DSX_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
  ];
  @ViewChild('calendar', {}) private calendar: any;
  allowedMaxDate: Date | undefined;
  allowedMinDate: Date | undefined;
  selectedStartDate: string | undefined;
  selectedEndDate: string | undefined;
  allServices: any[] = [];
  selectedServices: any[] = [];
  monthsList: string[] = [];
  private readonly EXTRA_PROGRAMS = [
    DASHBOARD_D2C,
    DASHBOARD_DX,
    DASHBOARD_WALMART_LUMINATE
  ]
  monthWiseCostData: any[] | undefined;
  allCloudServices: Set<string> = new Set();
  protected isDialogOpen = false;

  loading = false;
  openDialog() {
    this.isDialogOpen = true;
  }

  closeDialog() {
    this.isDialogOpen = false;
  }

  private fragmentSubscription: Subscription;

  constructor(private dashboardListService: DashboardListService, private cloudCostService: CloudCostService,
    private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private productDataService: ProductDataService) {
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.month = currentDate.getMonth();
    this.year = currentDate.getFullYear();
    this.monthOptions$ = of(getMonthOptions());
    this.selectedMonth = currentDate.getMonth();
    this.categoryProgramList.forEach(category => {
      category.data.sortable = true;
    });
    this.fetchProductDashboards();

    this.fragmentSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.route.snapshot.fragment;
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }
    });

    this.monthWiseCostData = [];


  }


  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }


  redirectionFunction = (productId: any, productName: string) => {
    if (productId) {
      const productDashboard = this.productDashboards?.find(dashboard => dashboard.id === productId);
      const navigationExtras: NavigationExtras = {
        state: {
          dashboardDetails: productDashboard
        }
      };
      if (productId == HYPHEN) this.router.navigate(['/se-metrics'], { fragment: 'dsx' });
      else
        this.router.navigate(['/product-dashboard/product-dashboard-view/', productId], { queryParams: { tab: 'Cost' }, ...navigationExtras });
    }
  }



  onYearChange(event) {
    this.year = new Date().getFullYear();
    this.fetchKPIsData();
  }

  private fetchKPIsData() {
    if (this.productDashboards.length) {
      this.setTableData(this.productDashboards);
    } else {
      this.fetchProductDashboards();
    }
  }


  private fetchProductDashboards() {
    const params = this.paramBuilder(0, '10');
    this.dashboardListService.fetchProductDashboards(params).subscribe(
      response => {
        if (response?.data) {
          this.productDashboards = response.data.sort((a, b) => { return a.name.toUpperCase().localeCompare(b.name.toUpperCase()) });
          this.setTableData(this.productDashboards)
        }
      },
      error => console.log(error)
    );
  }


  paramBuilder(page: number, pageSize: string): HttpParams {
    return new HttpParams()
      .set(API_PARAM_PAGE, page.toString())
      .set(API_PARAM_SIZE, pageSize)
  }

  getLastSixMonthsDetails(): void {
    const today = new Date();
    const numberOfMonths = 6;

    const startDate = new Date(today.getFullYear(), today.getMonth() - (numberOfMonths - 1), 1);


    const endDate = new Date(today.getFullYear(), today.getMonth(), 1);


    for (let i = 0; i < numberOfMonths; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const monthName = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      this.monthsList.unshift(`${monthName} ${year}`);
    }


    const formattedStartDate = formatDate(startDate, 'yyyy-MM', 'en');
    const formattedEndDate = formatDate(endDate, 'yyyy-MM', 'en');


    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
    this.payload = payload;

  }

  async setTableData(prdoductDashbords) {
    this.getLastSixMonthsDetails();

    const filteredKPI_NAMES = this.monthsList.filter(eachName => eachName !== environment.hideSEMetricsColumn);
    const tableColumns: any[] = [{ field: REPO.toLowerCase(), header: { twoLines: true, textHeaders: [PROGRAM.toUpperCase(), NAME.toUpperCase()] } }];
    filteredKPI_NAMES.forEach(kpiName => {
      const textHeaders: string[] = kpiName.toUpperCase().split('<\>');
      tableColumns.push({
        field: kpiName,
        header: {
          twoLines: true,
          textHeaders
        }
      });
    });
    const commercialAndConsumerData = [];
    const supplyChainData = [];
    const dsxData = [];
    if (prdoductDashbords?.length) {

      (async () => {
        await this.processDashboard(prdoductDashbords, filteredKPI_NAMES, commercialAndConsumerData, supplyChainData, dsxData);
      })().then(() => {
        if (Object.keys(this.dsxKpisNumbers).length) {
          filteredKPI_NAMES.forEach((kpiName) => {
            if (this.dsxRowData[kpiName]) {
              this.dsxRowData[kpiName] = '$' + Number(parseFloat(this.dsxRowData[kpiName]) / this.dsxKpisNumbers[kpiName]).toFixed(0);
              if (this.dsxRowData[kpiName].includes(NAN)) {
                this.dsxRowData[kpiName] = HYPHEN
              }
            } else {
              this.dsxRowData[kpiName] = HYPHEN
            }
          });
          //  commercialData.push(this.dsxRowData)  (we are not displaying aggregated values of DSX on se metrics table)
        }
        this.setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns);

      }).catch(error => {
        console.error(error);
      });

      this.setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns);
    }
  }

  async processDashboard(prdoductDashbords, KPI_NAMES, commercialAndConsumerData, supplyChainData, dsxData) {
    const promises = [];

    let dashboardCloudServiceMap: Map<string, string[]> = new Map();

    for (const dashboard of prdoductDashbords) {
      let cloudServiceList: string[] = [];

      if (dashboard?.cloudServiceName?.includes(',')) {
        cloudServiceList = dashboard.cloudServiceName
          .split(',')
          ?.map(service => service.trim())
          ?.filter(item => item.length);
      } else if (dashboard?.cloudServiceName) {
        cloudServiceList.push(dashboard.cloudServiceName.trim());
      }
      cloudServiceList.forEach(service => this.allCloudServices.add(service));
      dashboardCloudServiceMap.set(dashboard.name+'$$'+dashboard.id, cloudServiceList);
    }

    const allCloudServicesArray = Array.from(this.allCloudServices);
    const payload = { services: allCloudServicesArray, startDate: this.payload.startDate, endDate: this.payload.endDate };



    let costPromise;
    if (this.allCloudServices) {
      costPromise = this.cloudCostService.fetchMonthlyKubeCost(payload).toPromise()
        .then(resp => {
          if (resp) {
            dashboardCloudServiceMap.forEach((cloudServiceList: string[], dashboardDetails: string) => {
              const [dashboardName, dashboardId] = dashboardDetails.split('$$');
              const rowData = {
                'repo': {
                  name: dashboardName,
                  period: HYPHEN,
                  id: dashboardId
                }
              };

              if (!cloudServiceList.length) {
                KPI_NAMES.forEach((month) => {
                  rowData[month] = "-";
                });
              }

              let aggregatedCosts: { [key: string]: number } = {};
              for (const item of resp.totalCostByMonth) {
                if (cloudServiceList.includes(item.service)) {
                  for (const [month, cost] of Object.entries(item[item.service])) {
                    if (aggregatedCosts[month]) {
                      aggregatedCosts[month] += cost as number;
                    } else {
                      aggregatedCosts[month] = cost as number;
                    }
                  }
                }
              }

              Object.entries(aggregatedCosts).forEach(([monthName, monthTotalCost]) => {
                const yearMonthArr = monthName.split('-');
                const keyMonthData = getMonthName(yearMonthArr[1],"MMMM") + ' ' + yearMonthArr[0];
                monthName = keyMonthData;
              
                rowData[monthName] = monthTotalCost ? `$${Math.round(Number(monthTotalCost)).toLocaleString()}` : HYPHEN;

                if (rowData[monthName] !== HYPHEN && environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[monthName] = (this.dsxKpisNumbers[monthName] + 1) || 1;
                  const currentDsxRowCost = parseFloat(this.dsxRowData[monthName]) || 0;
                  this.dsxRowData[monthName] = '$' + (currentDsxRowCost + parseFloat(rowData[monthName])).toFixed(0);
                }
              });

              if (!this.EXTRA_PROGRAMS.includes(rowData?.repo?.name)) {
                const programLists = {
                  commercialAndConsumer: environment.commercialAndConsumerProgramList,
                  supplyChain: environment.supplyChainProgramList,
                  dsx: environment.DSX_PROGRAMS,
                };

                const category = Object.entries(programLists)?.find(([_, list]) => list?.includes(rowData?.repo?.name))?.[0];

                if (category) {
                  switch (category) {
                    case COMMERCIAL_AND_CONSUMER:
                      commercialAndConsumerData.push(rowData);
                      break;
                    case SUPPLY_CHAIN:
                      supplyChainData.push(rowData);
                      break;
                    case DSX:
                      dsxData.push(rowData);
                      break;
                  }
                }
              }
            });
          } else {
            for (const dashboard of prdoductDashbords) {
              const rowData = {
                'repo': {
                  name: dashboard.name,
                  period: HYPHEN,
                  id: dashboard.id
                }
              };

              KPI_NAMES.forEach((month) => {
                rowData[month] = "-";
              });

              if (!this.EXTRA_PROGRAMS.includes(rowData?.repo?.name)) {
                const programLists = {
                  commercialAndConsumer: environment.commercialAndConsumerProgramList,
                  supplyChain: environment.supplyChainProgramList,
                  dsx: environment.DSX_PROGRAMS,
                };

                const category = Object.entries(programLists)?.find(([_, list]) => list?.includes(rowData?.repo?.name))?.[0];

                if (category) {
                  switch (category) {
                    case COMMERCIAL_AND_CONSUMER:
                      commercialAndConsumerData.push(rowData);
                      break;
                    case SUPPLY_CHAIN:
                      supplyChainData.push(rowData);
                      break;
                    case DSX:
                      dsxData.push(rowData);
                      break;
                  }
                }
              }

            }
          }
        })
        .catch(
          (error) => {
            console.log('POST Error:', error);
          }
        )
    } else {
      costPromise = Promise.resolve();
    }

    promises.push(costPromise);
    await Promise.all(promises);
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let val1 = data1[event.field];
      let val2 = data2[event.field];
      let result = 0;
      if (event.field === REPO.toLowerCase()) {
        const name1 = val1?.name;
        const name2 = val2?.name;
        if (name1 == null && name2 != null) {
          result = -1;
        } else if (name1 != null && name2 == null) {
          result = 1;
        } else if (name1 == null && name2 == null) {
          result = 0;
        } else {
          result = name1.localeCompare(name2);
        }
      } else {
        const num1 = val1 && val1 !== HYPHEN ? parseFloat(val1.replace(/[\$,]/g, '')) : NaN;
        const num2 = val2 && val2 !== HYPHEN ? parseFloat(val2.replace(/[\$,]/g, '')) : NaN;
  
        if (isNaN(num1)) {
          return event.order === 1 ? 1 : -1;
        }
        if (isNaN(num2)) {
          return event.order === 1 ? -1 : 1;
        }
  
        result = num1 < num2 ? -1 : num1 > num2 ? 1 : 0;
      }
  
      return event.order * result;
    });
  }

  setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns) {
    this.categoryProgramList[0].data.tableColumns = tableColumns;
    this.categoryProgramList[0].data.tableData = commercialAndConsumerData;

    this.categoryProgramList[1].data.tableColumns = tableColumns;
    this.categoryProgramList[1].data.tableData = supplyChainData;

    this.categoryProgramList[2].data.tableColumns = tableColumns;
    this.categoryProgramList[2].data.tableData = dsxData;
  }

}

export interface CloudCostWidgetComponentApi {
  onDateSelection: (dateRange) => void
  clearMethod: (dateRange) => void
}
