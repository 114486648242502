import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ILineData } from '../chart-interfaces';

@Component({
  selector: "app-ngx-line-chart",
  templateUrl: "./ngx-line-chart.component.html",
  styleUrls: ["./ngx-line-chart.component.scss"],
})
export class NgxLineChartComponent {
  @Input() data: ILineData;

  @Output("selectedSprintTable") selectedSprintTable: EventEmitter<any> = new EventEmitter();
  constructor() {
   }
  onGraphPointSelect(event) {
    this.selectedSprintTable.emit(event);
    if(this.data.onDataSelection) this.data.onDataSelection(event);
  }
}
