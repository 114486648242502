<div *ngFor="let eachProgramData of categoryProgramList" class = "table-container">
    <div class="table-title">
        <h5>{{ eachProgramData?.title }}</h5>
    </div>
    <div class="table-component">
        <ng-container *ngIf="noDataAvailable; else showTable">
            <div class="noDataAvailable">{{ noDataMessage }}</div>
        </ng-container>
        <ng-template #showTable>
            <app-table-view [data]="eachProgramData?.data" [customSort]="customSort"
                [redirection]="redirectionFunction"></app-table-view>
        </ng-template>
    </div>
</div>