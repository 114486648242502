import { Component, Input, OnInit } from '@angular/core';
import {
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";
import { IRadialChartData } from '../chart-interfaces';
import { CHART_DEFAULT_COLORS } from '../../app-utility';
import { PERCENTAGE_SIGN } from '../../app-constants';


@Component({
  selector: 'app-radial-chart',
  templateUrl: './radial-chart.component.html',
  styleUrls: ['./radial-chart.component.scss']
})
export class RadialChartComponent implements OnInit{
  @Input() title : string;
  @Input() data : IRadialChartData;
  @Input() colorScheme:string[] = [...CHART_DEFAULT_COLORS];
  private readonly DEFAULT_HEIGHT: number = 240;
  private readonly TYPE: string = "radialBar";
  protected chartOptions: ApexChart;
  protected plotOptions: ApexPlotOptions;

  constructor() { }

  ngOnInit(): void {
    if(this.data){
      this.chartOptions = this.data.chartOptions || { height: this.DEFAULT_HEIGHT, type: "radialBar"};
      this.plotOptions = this.data.plotOptions || {
        radialBar: {
          dataLabels: {
            show: this.data.series && this.data.series.some(item => item !== 0), // FOR CHECKING SERIES ALL ITEMS ARE NOT ZERO
            name: {
              fontSize: "14px",
              offsetY: -4,
            },
            value: {
              fontSize: "16px",
              fontWeight: 600,
              offsetY: -4,
              color: "#fff",
              formatter: (val: any) => this.data.hidePercentageSign ? val : val+PERCENTAGE_SIGN
            },
            total: {
              show: this.data.showTotal,
              label: this.data.totalLable,
              formatter: (opts: any) => opts
            }
          },
          track: {
            background: '#333',
          },
          hollow: {
            size: '30%',
          }
        }
      };
    }
  }
}
