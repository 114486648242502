import { Component, Input } from '@angular/core';
import { IApexChartData } from '../apex-charts-inteface';
import { CHART_DEFAULT_COLORS } from '../../app-utility';

@Component({
  selector: 'app-apex-horizontal-bar-chart',
  templateUrl: './apex-horizontal-bar-chart.component.html',
  styleUrls: ['./apex-horizontal-bar-chart.component.scss']
})
export class ApexHorizontalBarChartComponent {
  @Input() title : string;
  @Input() data : IApexChartData;
  @Input() colorScheme:string[] = [...CHART_DEFAULT_COLORS];
  readonly DEFAULT_HEIGHT:number = 240;
  readonly DEFAULT_CHART_TYPE = {type: "bar", height: this.DEFAULT_HEIGHT,toolbar: {show: false}}
  readonly DEFAULT_PLOT_OPTION_CONFIG = {bar: {horizontal: true}}
  readonly DEFAULT_DATA_LABELS_CONFIG: { enabled: false, textAnchor: 'start' }          
  readonly SHOW_FALSE: { show: false }         

}
