import {HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {interval, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, map, startWith, take} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import { cloneDeep } from './app-utility';
import { API_HEADER_NO_CACHED } from './app-constants';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  appRoot = environment.appRoot;

  // qaApiUrl = environment.apiUrl;

  private dashboardRoute = this.appRoot+`/api/dashboard/`;

  private teamRoute = this.appRoot+ `/api/team/`;

  private scoreCardRoute = this.appRoot+`/api/score/metric/`;

  private dashboardV2Route = this.appRoot+`/api/v2/dashboard/`;

 // apiauditdel private dashboardAuditRoute = this.appRoot+'/apiaudit/auditresult/dashboard/title/';

  private dashboardCountRoute = this.appRoot+`/api/dashboard/count/`;

  private dashboardSprintWiseVelocities =  this.appRoot + '/api/velocity';

  private matchingDashboardRoute = this.appRoot+ `/api/dashboard/findDashboards`


  // private dashboardRoute = `${this.qaApiUrl}/api/dashboard/`;

  // private dashboardV2Route = `${this.qaApiUrl}/api/v2/dashboard`;

  // private dashboardAuditRoute = '/apiaudit/auditresult/dashboard/title/';

  // private dashboardCountRoute = `${this.qaApiUrl}/api/dashboard/count/`;

  dashboardSubject = new ReplaySubject<any>(1);
  scoreCard = new ReplaySubject<any>(1);

  private dashboardAuditSubject = new ReplaySubject<any>(1);

  public dashboardQualitySubject = new ReplaySubject<any>(1);

  private dashboardCountSubject = new ReplaySubject<any>(2);

  private dashboardRefreshSubject = new Subject<any>();

  private dashboardRefreshSubscription: Subscription;

  private REFRESH_INTERVAL_SECONDS = 3000;

  private dashboardId: string;

  public dashboardConfig$ = this.dashboardSubject.asObservable().pipe(filter(result => result));

  public dashboardAuditConfig$ = this.dashboardAuditSubject.asObservable().pipe(filter(result => result));

  public dashboardCountConfig$ = this.dashboardCountSubject.asObservable().pipe(filter(result => result));

  public dashboardQualityConfig$ = this.dashboardQualitySubject.asObservable().pipe(filter(result => result));

  public dashboardRefresh$ = this.dashboardRefreshSubject.asObservable();

  public componentId: string;

  public cloudServiceName: string;

  public productTeamDashboardList: any[];

  public productDashbaordDetails: any;

  constructor(private http: HttpClient) { }

  // Get dashboard by id
  getDashboard(dashboardId: string): Observable<any> {
    this.dashboardId = dashboardId;
    const params = {
      params: new HttpParams().set(API_HEADER_NO_CACHED, API_HEADER_NO_CACHED)
    };
    try {
      return this.http.get(this.dashboardRoute + dashboardId, params);
    }
    catch(e) {  
      console.log("Error while fetching dashboard",e)
      return e;
    }
  }

  // getScore by id 
  getScoreCard(dashboardId: string): Observable<any>{
    return this.http.get(this.scoreCardRoute + dashboardId);
  }

  loadDashboardAudits() {
    this.dashboardConfig$.pipe(map(dashboard => dashboard)).subscribe(dashboard => {
     // this.http.get<IAuditResult[]>(this.dashboardAuditRoute + dashboard.title).subscribe(res => this.dashboardAuditSubject.next(res));
    });
  } 

  subscribeDashboardRefresh() {
    this.dashboardRefreshSubscription = interval(1000 * this.REFRESH_INTERVAL_SECONDS).pipe(
      startWith(-1)).subscribe(res => this.dashboardRefreshSubject.next(res));
  }

  clearDashboard() {
    this.dashboardId = null;
    this.dashboardSubject.next(null);
    if (this.dashboardRefreshSubscription) {
      this.dashboardRefreshSubscription.unsubscribe();
    }
  }

  setDashboardId(dashboardId){
    this.dashboardId = dashboardId;
  }

  getDashboardId(){
    return this.dashboardId
  }

  upsertMultipleWidgets(widgetConfigs: any[], isEdit): Observable<any> {
    const apiCall = isEdit ? this.http.put(this.dashboardV2Route + this.dashboardId + '/widgets', widgetConfigs) : this.http.post(this.dashboardV2Route + this.dashboardId + '/widgets', widgetConfigs);
    
    try {
      return apiCall;
    }
    catch(e) {
      console.log("Error while saving widget",e)
      return e;
    }
  }

  // Clone the passed widget config, and post the updated widget to the API
  upsertWidget(widgetConfig: any): Observable<any> {
    widgetConfig = cloneDeep(widgetConfig);

    const widgetId = widgetConfig.id;
    console.log("widget id", widgetId)
    if (!!widgetId) {
      delete widgetConfig?.id;
    }

    const apiCall = widgetId ?
      this.http.put(this.dashboardV2Route + this.dashboardId + '/widget/' + widgetId, widgetConfig) :
      this.http.post(this.dashboardV2Route + this.dashboardId + '/widget', widgetConfig);


    try {
      return apiCall;
    }
    catch(e) {
      console.log("Error while saving widget",e)
      return e;
    }

  }

  // Take a new component and config returned by the API, and update the data locally.
  // Push this new version to subscribers.
  upsertLocally(newComponent: any, newConfig: any) {
    // Find and update component
    let tempDashboard$ = this.dashboardConfig$.pipe(take(1), map(dashboard => {
      if (newComponent == null) {
        return dashboard;
      }
      let foundComponent = false;
      dashboard.application.components.forEach((component: any, index: number) => {
        if (component.id === newComponent.id) {
          foundComponent = true;
          dashboard.application.components[index] = newComponent;
        }
      });
      if (!foundComponent) {
        dashboard.application.components.push(newComponent);
      }
      return dashboard;
    }));

    // Find and update config
    tempDashboard$ = tempDashboard$.pipe(map(dashboard => {
      let foundMatch = false;
      const filteredWidgets = dashboard.widgets.filter((config: any) => config.options.id === newConfig.options.id);
      filteredWidgets.forEach((config: any, index: number) => {
        foundMatch = true;
        dashboard.widgets[index] = {...config, ...newConfig};
      });
      if (!foundMatch) {
        dashboard.widgets.push(newConfig);
      }
      return dashboard;
    }));

    tempDashboard$.subscribe(dashboard => this.dashboardSubject.next(dashboard));
  }

  // Clone the passed widget config, and post the deleted widget to the API
  deleteWidget(widgetConfig: any): Observable<any> {
    widgetConfig = cloneDeep(widgetConfig);

    const widgetId = widgetConfig.id;
    if (widgetId) {
      delete widgetConfig.id;
    }

    const apiCall = widgetId ?
      this.http.put(this.dashboardV2Route + this.dashboardId + '/deleteWidget/' + widgetId, widgetConfig) : null;
    return apiCall;
  }

  // Take updated component sans deleted widget and config returned by the API, and update the data locally.
  deleteLocally(responseComponent: any, configToDelete: any) {
    // Find and update component
    let tempDashboard$ = this.dashboardConfig$.pipe(take(1), map(dashboard => {
      if (responseComponent == null) {
        return dashboard;
      }
      let foundComponent = false;
      dashboard.application.components.forEach((component: any, index: number) => {
        if (component.id === responseComponent.id) {
          foundComponent = true;
          dashboard.application.components[index] = responseComponent;
        }
      });
      if (!foundComponent) {
        dashboard.application.components.push(responseComponent);
      }
      return dashboard;
    }));

    // Find and remove widget config
    tempDashboard$ = tempDashboard$.pipe(map(dashboard => {
      dashboard.widgets = dashboard.widgets.filter((config: any) => config.options.id !== configToDelete.options.id);
      return dashboard;
    }));

    tempDashboard$.subscribe(dashboard => this.dashboardSubject.next(dashboard));
  }

  checkCollectorItemTypeExist(ciType: string): boolean {
    let collectorItems;
    let exists = false;
    this.dashboardConfig$.pipe(take(1), map(dashboard => dashboard)).subscribe(dashboard => {
      dashboard.application.components.forEach((component: any, index: number) => {
        collectorItems = dashboard.application.components[index].collectorItems;
        exists = Object.keys(collectorItems).some(type => type === ciType);
      });
    });
    return exists;
  }

  createDashboard(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type':  'application/json'})};
    return this.http.post(this.dashboardRoute, data, httpOptions);
  }


  editDashboard(data:any, dashboardId): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type':  'application/json'})};
    return this.http.put(this.dashboardRoute + dashboardId, data);
  }

  getToken() {
    const oktaStorage =  localStorage.getItem('okta-token-storage')
    return JSON.parse(oktaStorage)?.accessToken?.accessToken;
  }


  getOktaToken() {
    const oktaToken = this.getToken();
    return new Promise((resolve, reject) => {
      try {
        this.http.post(this.appRoot+ "/api/login",
          new HttpParams({}),
          { headers: {'Authorization': `Bearer ${oktaToken}`,'Content-Type': 'application/x-www-form-urlencoded'}, observe: 'response'})
          .subscribe(loggedIn => {
            const token = loggedIn.headers.get('x-authentication-token');
            if (token) {
              localStorage.setItem('access_token', token);
            }
            resolve(token)
          }, error => reject(error));
      }
      catch(err) {
        reject(err)
      }
  });
  }

  loadCounts() {
    ['Team', 'Product'].forEach(type => {
      this.http.get(this.dashboardCountRoute + type)
        .subscribe(count => this.dashboardCountSubject.next({ name: type, value: count }));
    });
  }

  setComponentId(id){
    this.componentId = id;
  }

  getComponentId(){
    return this.componentId;
  }

  getTeamById(id: string, componentId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams().set('component', componentId)
    };
    return this.http.get(this.teamRoute+ id, httpOptions);
  }

  setCloudServiceName(name){
    this.cloudServiceName = name;
  }

  getCloudServiceName(){
    return this.cloudServiceName;
  }

  setProductTeamDashboardList(productTeamDashboardList){
    this.productTeamDashboardList = productTeamDashboardList
  }

  getProductTeamDashboardList(){
    return this.productTeamDashboardList;
  }

  setProductDashbaordDetails(productDashboardDetails){
    this.productDashbaordDetails = productDashboardDetails
  }

  getProductDashbaordDetails(){
    return this.productDashbaordDetails;
  }

  fetchSprintWiseVelocities() {
    const componentId = this.productDashbaordDetails?.id
    const params = {
      params: new HttpParams().set('component',componentId)
    };
    return this.http.get<any>(this.dashboardSprintWiseVelocities, params).pipe(map(response => response));
  }

  getMatchingDashboards(searchQuery: string):Observable<any>{

    const params = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams().set('search',searchQuery)
    };
    console.log("calling get matching dashboards",params)
    return this.http.get<any>(this.matchingDashboardRoute, params).pipe(map(response => response));
    
  }
  
}
