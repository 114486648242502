<p-calendar
    #calendar 
    [view]="view"
    [name]="name"
    [(ngModel)]="rangeDates" 
    [selectionMode]="selectionMode" 
    [readonlyInput]="readonlyInput" 
    [dateFormat]="dateFormat" 
    [showIcon]="showIcon"
    [showButtonBar]="showButtonBar"
    [monthNavigator]="monthNavigator" 
    [yearNavigator]="yearNavigator"
    [placeholder]="placeholder"
    [maxDate]="allowedMaxDate"
    [minDate]="allowedMinDate"
    [styleClass]="styleClass"
    [disabled]="disabled"
    (onSelect)="handleDateRangeSelection()"
    (onClearClick)="handleClearData()"
/>
