import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placeholder-widget',
  templateUrl: './placeholder-widget.component.html',
  styleUrls: ['./placeholder-widget.component.scss']
})
export class PlaceholderWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
