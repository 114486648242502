<div style="padding: 0px">
  <div class="modal-content {{title}}" >
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="this.detailData && this.detailData.url"><a href="{{this.detailData.url}}" target="_blank">{{title}}</a></h4>
      <h4 class="modal-title" *ngIf="((!this.detailData || !this.detailData.url) && title != 'Pulls')">{{title}}</h4>
      <h4 class="modal-title detailtitle" *ngIf="((!this.detailData || !this.detailData.url) && title === 'Pulls')">Pull Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <ng-template appDetailModal></ng-template>
    <div class="modal-footer">

    </div>
  </div>
</div>
