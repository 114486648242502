import { Component, Input, OnInit } from '@angular/core';
import { IChipsViewData } from '../../charts/chart-interfaces';
import { CodeAnalysisDataSharedService } from 'src/app/product_widget_modules/code-analysis/code-analysis-data-shared.service';

@Component({
  selector: 'app-chips-view',
  templateUrl: './chips-view.component.html',
  styleUrls: ['./chips-view.component.scss']
})
export class ChipsViewComponent implements OnInit {
  @Input() data:IChipsViewData;
  @Input() title: string;
  DEFAULT_CHIP_COLOR:string = "#3B5660";

  constructor(private codeAnalysisDataService: CodeAnalysisDataSharedService) { }


  ngOnInit(): void {} 

  openDialog(event) {
    this.codeAnalysisDataService.triggerCodeAnalysisViolationsData(event);
    
  }
}
