<div class="widget dark-chart-theme" #areacontainerRef>
    <div class="chart-heading">
        {{title}}
    </div>
    <div class="detail-popup" *ngIf="data.componentId" [ngClass]="{'clickable': data?.clickableContent}"
        (click)="openDetailView(0)">
        <span class="storyPoints clickable">
            <img src="assets/svg/code-commit-solid.svg" class="icon" ngbTooltip="Code Commit Details" />
        </span>

    </div>
    <div class="detail-popup" *ngIf="data.componentId" [ngClass]="{'clickable': data?.clickableContent}"
        (click)="openDetailView(1)">
        <span class="storyPoints clickable">
            <img src="assets/svg/code-pull-request-solid.svg" class="icon" ngbTooltip="Pull Requests Details" />
        </span>
    </div>
    
    <div class="detail-popup" *ngIf="data.componentId" [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView(2)">
      <span class="storyPoints clickable" >
        <img src="assets/svg/users-solid.svg" class="icon" ngbTooltip="Top Contributors"/>
      </span>
    </div>
    <ngx-charts-bar-vertical-2d [view]="[500,300]" [scheme]="colorScheme" [yAxisTickFormatting]="yAxisFormatter" [yAxisTicks]="data.yAxisTicks"
        [results]="pullCommitData" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [animations]='true' [roundEdges]="true"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [barPadding]="'4'" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [groupPadding]="20" [timeline]="timeline" [legend]="showLegend" [legendPosition]="'right'" (select)="onSelect($event)">
    </ngx-charts-bar-vertical-2d>
</div>