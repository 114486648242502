import { Component, Input, Type, ViewEncapsulation } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';
import { DashStatus } from '../../dash-status/DashStatus';
import { DetailModalComponent } from '../../modals/detail-modal/detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRotationData } from './rotation-chart-interfaces';

export interface NgbPanelChangeEvent {
  nextState: boolean;
  panelId: string;
  preventDefault: () => void;
}
@Component({
  selector: 'app-rotation-chart',
  templateUrl: './rotation-chart.component.html',
  styleUrls: ['./rotation-chart.component.scss'],
  // imports: ['NgbAccordionModule'],
  encapsulation: ViewEncapsulation.None
})

export class RotationChartComponent extends ChartComponent {
  DashStatus: typeof DashStatus = DashStatus;

  private storiesData: any[] = [];
  private storyType;
  private storystatus;
  private dataAvailable: Boolean;
  private page = 1;
  private pageSize = 10;
  private collectionSize;
  public hidechart:boolean=false

  constructor(private modalService: NgbModal) {
    super();
    }


  @Input() detailView: Type<any>;
  @Input()

  set detailData(data: any) {
    if (data) {
      this.data = data.items;
      
      this.dataAvailable = true;
    } else {
      this.data = null;
      this.dataAvailable = false
    }
  }


  mySortingFunction = (a, b): number => {
    let aa:number=parseInt(a.key);
   let bb:number=+b.key
    return aa > bb ? 1 : (bb > aa ? -1 : 0);
  }


  ngOnInit() {
    if (this.data) {
      this.hidechart = this.data.hidechart1;
    } else {
      this.hidechart = false;
    }
   }


  openDetailView() {
    
    if (this.data && (this.data as IRotationData).clickableContent) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = 'Story Details';
      modalRef.componentInstance.detailData = this.data.items[0];
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.clickableContent;
    }  
  }

  getStoriesData() {
    this.storiesData = this.data.items[0][0].storiesData;
    this.collectionSize = this.storiesData.length;
  }

  unlockTabs(targetAgileType: string, currAgileType: string) {
    if ((targetAgileType === 'scrumkanban') || (targetAgileType === 'scrum' && currAgileType === 'Scrum' ||
      (targetAgileType === 'kanban' && currAgileType === 'Kanban'))) {
      return true;
    } else {
      return false;
    }
  }

  lastPanelId: string = null;
  defaultPanelId: string = "panel2";

  panelShadow($event: NgbPanelChangeEvent, shadow) {
    const { nextState } = $event;
    const activePanelId = $event.panelId;
    const activePanelElem = document.getElementById(activePanelId);
    if (!shadow.isExpanded(activePanelId)) {
      activePanelElem.parentElement.classList.add("open");
    }
    if (!this.lastPanelId) this.lastPanelId = this.defaultPanelId;
    if (this.lastPanelId) {
      const lastPanelElem = document.getElementById(this.lastPanelId);
      if (this.lastPanelId === activePanelId && nextState === false)
        activePanelElem.parentElement.classList.remove("open");
      else if (this.lastPanelId !== activePanelId && nextState === true) {
        lastPanelElem.parentElement.classList.remove("open");
      }
    }
    this.lastPanelId = $event.panelId;
  }
}
