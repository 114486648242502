import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-score-details',
  templateUrl: './score-details.component.html',
  styleUrls: ['./score-details.component.scss']
})
export class ScoreDetailsComponent implements OnInit {
  title = "Score Details"
  @Input() public scoretype:any;
  @Input() public score:any;
  @Input() public total:any
  @Input() public metrics:any;
  constructor(    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if(this.scoretype === 'WIDGET'){
      if(this.metrics[0]){
        this.metrics = this.metrics[0].children;
      }
    }
  }
  onSubmit() {
    this.activeModal.close();
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  

}
