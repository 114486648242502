import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {
  
  private selectedTab$ = new Subject<string>();
  selectedTabObservable$ = this.selectedTab$.asObservable();
  /*MAP TO STORE API CALL RESPONSE IN KEY VALUE PAIR. A KEY SHOULD FOLLOW THE BELOW APPROACH 
  FOR KEY NAMING SO THAT DATA CAN BE REUSED:
  Eg. const KEY_API = this.yourService.API_ENDPOINT+"-"+this.dashboardId+"-"+"this.filtersIfAny";
      const apiRecord = this.productDataService.apiCallsData.get(KEY_API);
       if(apiRecord){
            this.processDataAsPerYourRequirement(apiRecord);
          }else {
            this.yourService.makeAPICall(this.dashboardId).subscribe(resp => {
              this.productDataService.apiCallsData[KEY_API] = resp;
              this.processDataAsPerYourRequirement(apiRecord);
            });
        }
  */
  apiCallsData:Map<string, any> = new Map();
  dashboardId:string; 

  setSelectedTab(tab: string) {
    this.selectedTab$.next(tab);
  }

  setDashboardId(dashboardId){
    this.dashboardId = dashboardId
  }
  
}
