<div class = "details-header">
    <span class = "title">{{title}} 
        <i class="fa fa-info-circle" style = 'cursor: pointer;' *ngIf="projectLatestSnapshotData"
        tooltipClass="time-tooltip" [ngbTooltip]="projectLatestSnapshotData" aria-hidden="true" placement = "right"></i></span>
    <div class="details-header-view">
        <div class="detail-item" *ngFor="let item of data?.detailItems">
            <span class="title" *ngIf="item?.title">{{item.title}}</span>
            <span class="detail" *ngIf="item?.detail">{{item.detail}}</span>
        </div>
    </div>
</div>