import { Component } from '@angular/core';

import { IWidget, ScoreCard } from '../../interfaces';

@Component({
  template: '',
  styleUrls: ['./base-template.component.scss']
})
export class BaseTemplateComponent  {

  widgets: IWidget[];
  scoreCard : ScoreCard[];

  constructor() { }

}
