import { Component, Input } from '@angular/core';
import { IDeviationData} from '../chart-interfaces'
@Component({
  selector: 'app-deviation-chart',
  templateUrl: './deviation-chart.component.html',
  styleUrls: ['./deviation-chart.component.scss']
})
export class DeviationChartComponent  {

  isPositive:boolean=true;
  deviation: number =0;
  @Input() tooltipMessage : string;
  @Input() tooltipPosition : string;
  @Input() defectType: boolean;
  @Input() check : boolean;

  @Input()
  set data(data: IDeviationData) {
    if (data && !isNaN(Number(data.chartData))) {
      if(data.chartData<0) this.isPositive = false;
      this.deviation = Math.abs(Number(data.chartData));
    } else {
      this.deviation = Math.abs(Number(0));
    }
  }
  
}
