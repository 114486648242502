import { Component, Input, OnInit } from '@angular/core';
import { IDateSelectionCallbacks } from '../../interfaces';
import { DATE, MM_DD_YY, MM_DD_YYYY, RANGE, SELECT_DATE_RANGE } from '../../app-constants';

@Component({
  selector: 'app-prime-calender',
  templateUrl: './prime-calender.component.html',
  styleUrls: ['./prime-calender.component.scss']
})
export class PrimeCalenderComponent implements OnInit {
  @Input() handleDateRange:IDateSelectionCallbacks;
  @Input() rangeDates: Date[] = [];
  @Input() allowedMaxDate?:Date;
  @Input() allowedMinDate?:Date;
  @Input() readonlyInput?:boolean;
  @Input() showIcon?:boolean;
  @Input() showButtonBar?:boolean;
  @Input() monthNavigator?:boolean;
  @Input() yearNavigator?:boolean;
  @Input() disabled?:boolean;
  @Input() placeholder?:string;
  @Input() selectionMode?:string;
  @Input() styleClass?:string;
  @Input() dateFormat?:string;
  @Input() view?:string;
  @Input() name?:string;

  ngOnInit(): void {
    this.readonlyInput = this.readonlyInput ?? true
    this.showIcon = this.showIcon ?? true
    this.showButtonBar = this.showButtonBar ?? true
    this.monthNavigator = this.monthNavigator ?? true
    this.yearNavigator = this.readonlyInput ?? true
    this.disabled = this.disabled ?? false
    this.placeholder = this.placeholder ?? SELECT_DATE_RANGE
    this.selectionMode = this.selectionMode ?? RANGE
    this.dateFormat = this.dateFormat ?? MM_DD_YY;
    this.view = this.view ?? DATE

  }


  handleClearData() {
    this.handleDateRange.clearMethod(this.rangeDates)
  }  

  handleDateRangeSelection() {
    this.handleDateRange.onDateSelection(this.rangeDates)
  }
}
