import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QaMetricsDataSharedService {
  private titleChangeSubject = new Subject<void>();
  private bugStatusChange = new Subject<String>();
  private manualTestPlanData = new Subject<String>();

  titleChange$ = this.titleChangeSubject.asObservable();
  bugStatusChange$ = this.bugStatusChange.asObservable();
  manualSelectData$ = this.manualTestPlanData.asObservable();

  triggerTitleChange() {
    this.titleChangeSubject.next();
  }

  triggerBugStatusChange(val){
    this.bugStatusChange.next(val);
  }

  triggerOnSelect(val){
    this.manualTestPlanData.next(val);
  }
}
