<div class="vertical-group-chart-container">
  <div class="header">
    <span class="title" *ngIf="title">{{title}}</span>
    <span class="align-contents">
      <select class = 'select-option' *ngIf = 'data && data.chartData.length'>
        <option class='option' value="90" selected>{{lastNinetyDays}}</option>
        <option class='option' value="180" disabled>{{lastOneEightyDays}}</option>
      </select>
    </span>
  </div>
  <ngx-charts-bar-vertical-2d *ngIf="data && data.chartData.length else msg_block"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [scheme]="colorScheme || DEFAULT_CHART_COLORS"
    [view]="data.view"
    [results]="data.chartData"
    [gradient]="data.gradient || false"
    [xAxis]="data.showXAxis || true"
    [yAxis]="data.showYAxis || true"
    [legend]="data.showLegend || true"
    [legendTitle]="data.legendTitle || ''"
    [showXAxisLabel]="data.showXAxisLabel || true"
    [showYAxisLabel]="data.showYAxisLabel || true"
    [showGridLines]="data.showGridLines"
    [showDataLabel]="data.showDataLabel || false"
    [noBarWhenZero]="data.noBarWhenZero || false"
    [roundDomains]="data.roundDomains || false"
    [groupPadding]="data.groupPadding || 24"
    [barPadding]="data.barPadding || 8"
    [wrapTicks]="data.wrapTicks || true"
    [yAxisTickFormatting]="data.yAxisTickFormatting"
    [xAxisTickFormatting]="data.xAxisTickFormatting"
    [dataLabelFormatting]="data.dataLabelFormatting"
    [yAxisTicks]="data.yAxisTicks"
    (select)="onSelect($event)"
    >
  </ngx-charts-bar-vertical-2d>
  <ng-template #msg_block>
    <span class="msg_block">{{data?.loadingMessage}}</span>
  </ng-template>
</div>
