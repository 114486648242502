import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartComponent } from '../chart/chart.component';
import { IBarChartData } from './vertical-bar-chart-interfaces';
import { DetailModalComponent } from '../../modals/detail-modal/detail-modal.component';
import { RepoWidgetComponent } from 'src/app/widget_modules/repo/repo-widget/repo-widget.component';
import { COMMITS, COMMITS_TITLE, DETAILS, FIFTEEN, MY_SIZE, NINETY, PULLS, PULL_TITLE, SIXTY, STRING, THIRTY, TOP_CONTRIBUTORS, WEEK_WISE_DATA } from '../../app-constants';
import { formatToDayAndMonth, getMonthText } from '../../app-utility';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent extends ChartComponent {

  data_: any[] = [];
  weeksCount: any[] = [];
  pullCommitData: any[] = [];

  constructor(private modalService: NgbModal, public repoWidget: RepoWidgetComponent) {
    super();
  }
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  tooltipDisabled = false;
  showXAxisLabel = true;
  showYAxisLabel = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  timeline = false;
  noBarWhenZero = true;
  maxYAxisTickLength = 8;
  maxXAxisTickLength = 8;
  yAxisTickFormatting: (val: number) => string = this.formatInteger;
  xAxisTickFormatting: (val: Date) => string = this.formatToDayAndMonth;

  ngOnInit(): void {

    if(this.repoWidget.selectDays === FIFTEEN) {
      this.data.dataPoints.map(el => {
        if (el.name === COMMITS || el.name === PULLS) {
          el.series.map(ele => {
            this.data_.push(new Date(ele.name));
          });
        }
      });
  
      this.data_.sort(function (a, b) {
        return a - b;
      });
  
      let uniqueArray = this.data_
        .map(function (date) { return date.getTime() })
        .filter(function (date, i, array) {
          return array.indexOf(date) === i;
        })
        .map(function (time) { return new Date(time); });
  
      uniqueArray.forEach(element => {
        let commitDataValue;
        let pullDataValue;
        this.data.dataPoints.map(ele => {
          if (ele.name === COMMITS) {
            ele.series.map(currDate => {
              if (currDate.name.getTime() === element.getTime()) {
                commitDataValue = currDate.value;
              }
            });
          }
          if (ele.name === PULLS) {
            ele.series.map(currDate => {
              if (currDate.name.getTime() === element.getTime()) {
                pullDataValue = currDate.value;
              }
            });
          }
        });
        this.xAxisLabel = "";
        this.pullCommitData.push({
          name: this.formatToDayAndMonth(element),
          series: [
            {
              name: COMMITS,
              value: (commitDataValue === undefined ? 0 : commitDataValue)
            },
            {
              name: PULLS,
              value: (pullDataValue === undefined ? 0 : pullDataValue)
            }
          ]
        })
      });
    }
    // For days 30 or 60 will show in weeks for 90 and more days will show up in month wise
    if (this.repoWidget.selectDays === THIRTY || this.repoWidget.selectDays === SIXTY || this.repoWidget.selectDays === NINETY) {
      this.data.dataPoints.map(el => {
        if (el.name === COMMITS || el.name === PULLS) {
          el.series.map(ele => {
            this.weeksCount.push(ele.name)
          });
        }
      });
      if(this.repoWidget.selectDays === THIRTY || this.repoWidget.selectDays === SIXTY){
        this.weeksCount.sort((a, b) => {
          const getDate = dateString => {
            const [day, month, year] = dateString.split(' ')[0].split('/');
            return new Date(`${year}-${month}-${day}`).getTime();
          };
        
          const dateA = getDate(a);
          const dateB = getDate(b);
        
          return dateA - dateB;
        });
      }

      let weekUniqueArray = this.weeksCount.filter((value, index, array) => array.indexOf(value) === index);
      weekUniqueArray.forEach(element => {
        let commitDataValue;
        let pullDataValue;
        this.data.dataPoints.map(ele => {
          if (ele.name === COMMITS) {
            ele.series.map(currDate => {
              if (currDate.name === element) {
                commitDataValue = currDate.value;
              }
            });
          }
          if (ele.name === PULLS) {
            ele.series.map(currDate => {
              if (currDate.name === element) {
                pullDataValue = currDate.value;
              }
            });
          }
        });
        if(this.repoWidget.selectDays === NINETY){
          this.xAxisLabel = "";
          element = this.numbertoMonth(element);
        }
        if(this.repoWidget.selectDays === THIRTY || this.repoWidget.selectDays === SIXTY){
          this.xAxisLabel = WEEK_WISE_DATA;
          element = this.numbertoWeekNumber(element);
        }
        this.pullCommitData.push({
          name: element,
          series: [
            {
              name: COMMITS,
              value: (commitDataValue === undefined ? 0 : commitDataValue)
            },
            {
              name: PULLS,
              value: (pullDataValue === undefined ? 0 : pullDataValue)
            }
          ]
        })
      });
    }
  }

  numbertoWeekNumber(val): string {
    const date = val.split('to');
    return date[0];
  }
 
  numbertoMonth(val): string {
    return getMonthText(val);
  }

  formatInteger(val: number): string {
    if (Number.isInteger(val)) {
      return val.toFixed(0);
    }
    return '';
  }

  formatToDayAndMonth(val: Date): string {
    if(this.repoWidget.selectDays === THIRTY || this.repoWidget.selectDays === SIXTY){
      // console.log(val);
    }
    
    if(val){
    return val?.getDate() + '/' + (val?.getMonth() + 1) + '/' + val?.getFullYear();
    }
  }

  isLegend = (event) => typeof event === STRING;
  openDetailView(isPR = 0) {
	  if(isPR == 2){
      const modalRef = this.modalService.open(DetailModalComponent,{ size: MY_SIZE });
      modalRef.componentInstance.title = TOP_CONTRIBUTORS;
     
      modalRef.componentInstance.detailData = {componentId : this.data.componentId, showCount: true};
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
    let filterBy = isPR ? PULLS : COMMITS
    if (isPR != 2 && this.data && (this.data as IBarChartData).detailComponent || (this.isLegend(event))) {
      const modalRef = this.modalService.open(DetailModalComponent);
      modalRef.componentInstance.title = `${isPR ? PULL_TITLE : COMMITS_TITLE} Details`;
      const dataset = this.data.dataPoints.find(i => i.name === filterBy);
      let allData = []
      dataset.series.forEach(i => {
        allData = [...allData, ...i.data]
      });
      modalRef.componentInstance.detailData = { data: allData, componentId: this.data.componentId,filterBy};
      (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
    }
  }

  onSelect(event): void {
    if(this.repoWidget.selectDays===FIFTEEN){
      if (this.data && (this.data as IBarChartData).detailComponent) {
        const modalRef = this.modalService.open(DetailModalComponent);
        modalRef.componentInstance.title = (event && event.label) ? event.label : DETAILS;
        const dataset = this.data.dataPoints.find(i => i.name === event.label);
        modalRef.componentInstance.detailData = dataset.series.find(i => this.formatToDayAndMonth(i.name) === event.series);
        (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
      }
    }else if(this.repoWidget.selectDays===THIRTY || this.repoWidget.selectDays===SIXTY || this.repoWidget.selectDays===NINETY) {
      if (this.data && (this.data as IBarChartData).detailComponent) {
        const modalRef = this.modalService.open(DetailModalComponent);
        modalRef.componentInstance.title = (event && event.label) ? event.label : DETAILS;
        const dataset = this.data.dataPoints.find(i => i.name === event.label);
        if(this.repoWidget.selectDays === THIRTY || this.repoWidget.selectDays === SIXTY){
          modalRef.componentInstance.detailData = dataset.series.find(i => this.numbertoWeekNumber(i.name) === event.series);
        }
        else if(this.repoWidget.selectDays === NINETY){
          modalRef.componentInstance.detailData = dataset.series.find(i => this.numbertoMonth(i.name) === event.series);
        }
        else{
          modalRef.componentInstance.detailData = dataset.series.find(i => i.name === event.series);
        } 
        (modalRef.componentInstance as DetailModalComponent).detailView = this.data.detailComponent;
      }
    }
  }

  yAxisFormatter(val){
    return Number(val).toFixed(0);
  }
}
