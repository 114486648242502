import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import moment from "moment";
import { ASTERIX, MANUAL, SINGLE_QUOTES } from "./app-constants";

export const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};


export const formatToKCash = n => {
   return +(n / 1e3).toFixed(1)
};


export const  percentChange = (a, b) =>  (( ( a - b ) / ( ( a + b ) / 2 ) ) * 100 ).toFixed(2);

export const percentCalculate = (v1, v2) => ((v1 - v2) / v2 * 100).toFixed(2);


export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const CHART_DEFAULT_COLORS = ['#007EB5','#A35079','#B992EB','#AAAAAA', '#994900',
'#b12b2b', '#5AA454', '#AAAAAA', '#00FFFF', '#0000FF',
'#00008B', '#ADD8E6', '#800080', '#FFFF00', '#00FF00',
'#FF00FF', '#FFC0CB', '#7FFFD4', '#808000', '#FFA500'];

export const getLastUrlSegment = (url) => {
    return new URL(url).pathname.split('/').filter(Boolean).pop();
}

export const getFortifyBoardURL = (projectId) => {
    return 'https://ssc.pepasm.net/ssc/html/ssc/version/'+projectId;
  }
  
export const getTeamDashboardLink = (dashboardId) => {
    return 'dashboard/dashboard-view/'+dashboardId;
}

export const getWikiLink = () => {
  return "https://dev.azure.com/PepsiCoIT/Global_Data_Project/_wiki/wikis/Global_Data_Project.wiki/58004/SE-Metrics-onboarding-prerequisites"
}

export const capitalizeFirstLetter = (str:string, capitalizeEachWord=false) => {
    if(capitalizeEachWord && str.includes(' ')){
        return str.split(' ').map(word=>word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const convertToGMTTimeStamp = (date) =>{
    let formattedDate = new Date(date)
    const timezoneOffset = formattedDate.getTimezoneOffset();
    if (timezoneOffset > 0) {
      formattedDate.setMinutes((24 * 60) - (timezoneOffset + 1));
    } else {
      formattedDate.setMinutes(-timezoneOffset);
    }
    return new Date(formattedDate.toUTCString())
  }

  export const numToKFormatter = (num) => {
    const number = Math.round(num);
    if (number < 1000) {
      return number/1000+"K";
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
    }
  }

  export const numberFormatterWithCurrencyPrefix = (num, kFormatter=true, prefix="$") => {
    return `${prefix}${kFormatter ? numToKFormatter(num): num}`;   
  }

  export const convertToUTCDateFormat = (date,format:string) => {
    if(date !== '#NA'){
      return moment(new Date(date)).utc().format(format);
    }else{
      return date;
    } 
  }

  export const fetchPercentage = (currentValue:number,totalValue:number) => {
    return Math.round((currentValue/totalValue)*100);
  }

  export const fetchAverage = (data,sumVariable='') => {
    //sum of values from array
    const sum = getSumFromArray(data,sumVariable);
    //getting average
    const average = Math.round(sum/data.length);
    return average
  }

  export const getSumFromArray = (data,sumVariable='') => {
    const sum = data.map((dataInstance) => 
    Number(sumVariable ? dataInstance[sumVariable] : dataInstance)).reduce((current, next) => Number(current) + Number(next));
    return sum;
  }

  export const sortForDates = (data,sortVariable,type) => {
    if(type==='asc'){
      data.sort((curr , prev) => new Date(curr[sortVariable]).getTime() - new Date(prev[sortVariable]).getTime() );
    }else{
      data.sort((curr , prev) => new Date(prev[sortVariable]).getTime() - new Date(curr[sortVariable]).getTime() );
    }
    return data;
  }

  export const calculatePercentageBasedOnThreshold = (average ,threshold) => {
    const velocity = Math.round(((average - threshold)/threshold)*100);
    return velocity;
  }
  
  export const convertToDateFormat = (timestamp: number) => {
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2); 
    return `${month}/${day}/${year}`;
  }

  export const formatToDayAndMonth = (val: Date) =>{
    if(val != null && !val.toString().includes("/")){
      return val?.getDate() + '/' + (val?.getMonth() + 1) + '/' + val?.getFullYear();
    }

    return val?.toString()
    
  }

  export const monthOrder = (val) => {
    const monthOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthOrder.indexOf(val);
  }

  export const getMonthText = (val) =>{
    switch(val) {
      case '1':
        return "Jan";
      case '2':
        return "Feb";
      case '3':
        return "Mar";
      case '4':
        return "Apr";
      case '5':
        return "May";
      case '6':
        return "Jun";
      case '7':
        return "Jul";
      case '8':
        return "Aug";
      case '9':
        return "Sep";
      case '10':
        return "Oct";
      case '11':
        return "Nov";
      case '12':
        return "Dec";
      default:
        return '';
    }
  }

  export const currentFormattedDate = () =>{
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      return `${monthNames[new Date().getMonth()]}-${new Date().getFullYear()}`;
  }

  export const convertDateFormatWithMoment = (data : any, inputFormat: any, resultFormat: any) => {
    return moment(data,inputFormat).format(resultFormat);
  }

  export const commaSeparatedAmount = (x:any) => {
    if(x){
      var parts = x?.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }else{
      return x;
    }
  }

  export const removeDollarAndComma = (val:any) => {
    //For Removing $ & commas
    return val && Number(val.replace(/\$/g, '').replace(",", ""));
  }

  export const roundPercentCalculate = (a,b) => {
   return  Math.round(a/b *100) || 0;
  }

  export const getProgramDashboardURL=(programDashboardId)=> {
    return 'product-dashboard/product-dashboard-view/' +programDashboardId;
  }

  export function validateTags(tagValue: string): boolean {
    const isValidLength = tagValue.length <= 100;
    return isValidLength;
  }

  export function removeNullFromArray(array:any){
    return array.filter(function(el) { return el; });
  }

  export function arrayEquals(a: string | any[], b: string | any[]) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  export const groupBy = (dataArray: any[], groupByKey: any) => {
    let result = {};
    if(dataArray?.length && groupByKey){
      result = dataArray.reduce((obj,val,i)=>((obj[groupByKey(val,i,dataArray)]||=[]).push(val),obj),{});
    }
    return result;
  }

  export const cloneDeep = (obj:any) => {
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
  }

  export function createMultipleEmailValidator() :ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
  
        const value = control.value;
        if (!value) {
            return null;
        }
  
        // this regex is to check if the email is valid and comma separated
        const hasValidCommaSeparatedEmail = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/.test(value);
  
        return !hasValidCommaSeparatedEmail ? {emailIssue:true}: null;
    }
  }
 
  export function getPrefix(resp: any): string {
    return resp === MANUAL ? ASTERIX : SINGLE_QUOTES;
  }

  export class ValidationUtils {
    static datePatternValidator() {
      return Validators.pattern('\\d{4}-(0[1-9]|1[0-2])');
    }
  
    static numericValidator() {
      return Validators.pattern('^[0-9]+$');
    }
  }

  export function getMonthName(monthNumber,format:string) {
    return moment().month(monthNumber - 1).format(format);
  }

  export function getYearOptions(): number[] {
    const currentYear = new Date().getFullYear();
    return [currentYear];
  }

  export function  getMonthOptions(): { value: number, label: string }[] {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const options = [];

    for (let i = currentMonth; i >= 0; i--) {
      const month = i;
      const year = currentYear;

      const label = new Date(year, month).toLocaleString('default', { month: 'long' });

      options.push({
        value: month + 1,
        label: `${label}`
      });
    }
    return options;
  }
