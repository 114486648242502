import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-pipeline-chart',
  templateUrl: './pipeline-chart.component.html',
  styleUrls: ['./pipeline-chart.component.scss']
})
export class PipelineChartComponent extends ChartComponent implements OnInit {
  gridSizes = [12, 12, 6, 4, 3, 'fifths', 2, 'sevenths', 'eigths', 'ninths'];
  colGridSize: any;
  
  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.colGridSize = this.gridSizes[this.data.key.length]
  }

}
