<br>

<p-table #dt1 [globalFilterFields]="['Name']" class="table-striped" [columns]="selectedColumns" [scrollable]="true"
    scrollHeight="420px" [value]="ownerStatus">


    <ng-template pTemplate="caption">
        <div class="container">
            <div class="row">

                <div class="col-6">
                    <p-multiSelect [options]="col1" [(ngModel)]="selectedColumns" (onChange)="onColumnsChange()" optionLabel="header"
                        selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}"
                        placeholder="Choose Columns" class="col-md-6"  filter="filter" ></p-multiSelect>

                </div>
                <div class="col-6 heading text-end">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search User Name" />
                    </span>
                </div>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="">User Name</th>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
            <th>Total</th>
            <th>Story Points</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-ownerStatus let-columns="columns">
        <tr>
            <td>{{ownerStatus.Name}}</td>
            <td *ngFor="let col of columns">
                {{ownerStatus[col.field]}}
            </td>
            <td>{{ownerStatus.Total}}</td>
            <td>{{ownerStatus.Estimates}}</td>
        </tr>
    </ng-template>

    <div class="p">644564</div>

</p-table>