<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title ">Score Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="app-score-details">
      <!-- <div>
        <span>Score:</span>
        <span class="d-inline-block"><app-score-card  [data]="score" [maxScore]="total" [enableClick]="false" title="score"></app-score-card> </span> 
      </div> -->
      <div>
          <table id="bootstrapOverrides"  class="table">
              <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Message</th>
                <th>Weight</th>
                <th>Score</th>
              </tr>
              </thead>
              <ng-container *ngFor="let score of metrics" >
                <tr>
                  <td>
                    {{score.displayName}}
                  </td>
                  <td>
                    {{score.state}}
                  </td>
                  <td>
                      {{score.message}}
                  </td>
                  <td>
                   {{score.weight}}
                  </td>
                 <td>   
                   <!-- <span>{{score.score}}</span>  -->
                    <span class="d-inline-block">
                      <app-score-card  [data]="score.score" [maxScore]="score.total" [enableClick]="false"  title="score.score"></app-score-card>  </span>
                     </td>
                </tr>
              </ng-container>
            </table>
      </div>
    
    </div>
  </div>
</div>
