<div class="container">
  <div class="row">
    <div class="col-8" >
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
    <div class="col-3">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
  </div> -->
</div>
