import { Component, Input } from '@angular/core'; 
@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss']
})
export class AppSpinnerComponent {
  private DEFAULT_STYLES = {width: '32px', height: '32px'}; 
  @Input() styles?:any = this.DEFAULT_STYLES; 
}
