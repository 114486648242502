<div class="placeholder">
  <div class="container d-flex h-100">
    <div class="mx-auto">
      <h1>
        PLACEHOLDER WIDGET
      </h1>
    </div>
  </div>
</div>

