import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    OktaAuthGuard,
    OktaCallbackComponent,
  } from '@okta/okta-angular';
import { SEMetricsComponent } from './se-metrics/se-metrics.component';

const routes: Routes = [
  { path: 'user', loadChildren: () =>
      import('./user/user.module').then(m => m.UserModule) },
  { path: 'build', loadChildren: () =>
      import('./widget_modules/build/build.module').then(m => m.BuildModule) },
  { path: 'collectorItem', loadChildren: () =>
      import('./viewer_modules/collector-item/collector-item.module').then(m => m.CollectorItemModule) },
  { path: 'dashboard', loadChildren: () =>
      import('./screen_modules/team-dashboard/team-dashboard.module').then(m => m.TeamDashboardModule) , canActivate: [ OktaAuthGuard ]},
  { path: 'product-dashboard', loadChildren: () =>
      import('./screen_modules/product-dashboard/product-dashboard.module').then(m => m.ProductDashboardModule) , canActivate: [ OktaAuthGuard ]},
  { path: 'admin', loadChildren: () =>
      import('./admin_modules/admin_dashboard/admin-dashboard-modules').then(m => m.AdminDashboardModule) },
  { path: 'audits', loadChildren: () =>
      import('./screen_modules/nfrr/nfrr.module').then(m => m.NfrrModule)},
      {
        path:'self-onboarding',loadChildren: () =>
        import('./screen_modules/self-onboarding/self-onboarding.module').then(m => m.SelfOnboardingModule), canActivate: [OktaAuthGuard]},
      
  { path: '', loadChildren: () =>
      import('./landing_page/landing-page.module').then(m => m.LandingPageModule), pathMatch: 'full', canActivate: [OktaAuthGuard] },
      {
        path: 'login/callback',
        component: OktaCallbackComponent,
      },
      { path: 'se-metrics', component: SEMetricsComponent },
  { path: '**', redirectTo: '/' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation:'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
