<div class="widget dark-chart-theme"  #piegridcontainerRef>
  <div class="chart-heading">
    {{title}}
  </div>
  <app-pie-grid
    [view]="[piegridcontainerRef.offsetWidth, 200]"  
    [scheme]="colorScheme"
    [results]="data?.results"
    [designatedTotal]="data?.designatedTotal"
    [customLabelValue]="data?.customLabelValue"
    [useCustomLabelValue]="data?.useCustomLabelValue"
    [coverageLabelValue]="data?.coverageLabelValue"
    [useCoverageLabelValue]="data?.useCoverageLabelValue"
    [label]="data?.label"
    [coverageLabel]="data?.coverageLabel"
    [animations]='true'>
  </app-pie-grid>
</div>
