import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
constructor(private router: Router,private auth: AuthService) {
    this.setUserData();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
    this.updateAppDynamicsPage();
    });
  }

  updateAppDynamicsPage(): void {
    const pathname = this.router.url; 
    (window as any).customPageTitle = pathname;
  }

  setUserData(): void {
    const pathname = this.router.url; 
    const userData = {
        gpid: this.auth.getGPID() || "",
        email: this.auth.getUserEmail() || "",
        FirstName: this.auth.getFirstName() || "",
        LastName: this.auth.getLastName()|| "",
        userId :this.auth.getUserId() || "",
        language: navigator?.language,
    };

    (window as any).customUserData = {
      ...(window as any).customUserData,
      ...userData,
    };
  

  }

}
