import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CHART_DEFAULT_COLORS } from '../../app-utility';
import { IChartData } from '../chart-interfaces';
import { LAST_ONE_EIGHTY_DAYS, WIDGET_DATA_INFO } from '../../app-constants';
import { QaMetricsDataSharedService } from 'src/app/product_widget_modules/qa-metrics/qa-metrics-data-shared.service';


@Component({
  selector: 'app-vertical-group-chart',
  templateUrl: './vertical-group-chart.component.html',
  styleUrls: ['./vertical-group-chart.component.scss']
})
export class VerticalGroupChartComponent {
  @Input() title: string;
  @Input() data: IChartData;
  @Input() colorScheme: any;
  @Input() xAxisLabel: string = '';
  @Input() yAxisLabel: string = '';

  lastNinetyDays: string = WIDGET_DATA_INFO;
  lastOneEightyDays: string = LAST_ONE_EIGHTY_DAYS;

  DEFAULT_CHART_COLORS: any = { domain: [...CHART_DEFAULT_COLORS]};
  
  constructor(private qaMetricsDataService: QaMetricsDataSharedService) { }

  onSelect(event) {
    this.qaMetricsDataService.triggerOnSelect(event);
  }
}
