import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IComboChartData } from './combo-multi-bar-chart-interface'

@Component({
  selector: 'app-combo-multi-bar-chart',
  templateUrl: './combo-multi-bar-chart.component.html',
  styleUrls: ['./combo-multi-bar-chart.component.scss']
})

export class ComboMultiBarChartComponent implements OnInit {

  @Input() title: string;
  @Input() data: IComboChartData;
  @Output("selectedChartData") selectedChartData: EventEmitter<any> = new EventEmitter();
  options: any;

  ngOnInit(): void {
    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: '#D0D0D0',
          },
          position: 'bottom'
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#D0D0D0',
            beginAtZero: true,
          },
        }
      }
    }
  }

  onSelected(event){
    this.selectedChartData.emit(event?.element);
  }
}
