//STATUS CONSTANTS
export const SUCCESS = 'Success';
export const FAILURE = 'Failure';
export const OTHERS = 'Others';
export const OK = 'OK';
export const FAIL = 'FAIL';
//-------END-------

//WIDGETS & COLLECTOR TYPES
export const OVERVIEW = 'Overview';
export const BUILD = 'Build';
export const FEATURE = 'Feature';
export const MONITOR = 'Monitor';
export const DEPLOY = 'Deploy';
export const REPO = 'Repo';
export const COMMITS ='Commits';
export const PULL_REQUESTS ='Pull Requests';
export const REPO_NAME = 'TEAM NAME';
export const KPI_NAME = 'KPI NAME';
export const ENV_NAME = 'Environment Name';
export const STATIC_CODE_ANALYSIS = 'Static Code Analysis';
export const SECURITY_ANALYSIS = 'SecurityAnalysis';
export const FORTIFY_SECURITY_ANALYSIS = 'Fortify Security Analysis';
export const SECURITY_ANALYSIS_BY_REPO = 'Fortify Security Analysis by Repository';
export const OPEN_SOURCE = 'Open Source';
export const TEST = 'Test';
export const INFRA_SCAN = 'Infra Scan';
export const QA = 'QA';
export const CODE_QUALITY = 'CodeQuality';
export const DEPLOYMENT = 'Deployment';
export const SCM = 'SCM';
export const STATIC_SECURITY_SCAN = 'StaticSecurityScan';
export const LIBRARY_POLICY = 'LibraryPolicy';
export const INFRASTRUCTURE_SCAN = 'InfrastructureScan';
export const QA_METRICS = 'QA Metrics';
export const FEATURES = 'Features';
export const COST = 'Cost';
export const ON_TIME_DELIVERY = 'On Time Delivery';
export const PROGRAM_NAME = 'Program Name';

//-------END-------

export const AGO = 'ago';
export const DESTROYED = 'destroyed';
export const TEAMS = 'TEAMS';
export const PROJECT_ID = 'Project ID';
export const LAST_SNAPSHOT_DATE = 'Last Snapshot Date';
export const NAME = 'name';
export const TEAM = 'team';
export const COUNT = 'count';
export const PULLCOUNT = 'pullCount';
export const PULL_COUNT = '# OF PULL REQUESTS';
export const COMMITS_COUNT = '# OF COMMITS';
export const OWNER = 'Owner'
export const PRODUCT_DASHBOARD = 'Product Dashboard';
export const PRODUCT_COST_BY_MONTH = 'Azure Cost by Month';
export const COST_BY_RESOURCE_GROUP = 'Azure Cost by Resource Group';
export const COST_BY_REGION = 'Azure Cost by Region';
export const COST_BY_APPLICATION = 'Azure Cost by Application';
export const LAST_MONTH = 'LAST MONTH';
export const BUILD_METRICS = 'Build Metrics';
export const DEPLOY_METRICS = 'Deploy Metrics';
export const BUILD_TABLE = 'Build Breakdown';
export const DEPLOY_TABLE = 'Deploy Breakdown';
export const TOP_DEVELOPERS = 'Top 10 Developers';
export const COMMMITS_AND_PULL_REQUESTS_TRENDS ='Commits and Pull Requests Trends'
export const STATUS = 'STATUS';
export const TOTAL = 'Total';
export const TOTAL_STORY_POINTS = 'Total Story Points';
export const PRODUCT = 'Product';
export const PROGRAM = 'Program';
export const DASHBOARDS = 'Dashboards';
export const NO_DATA_MSG = 'No data found!';
export const NO_DATA_AVAILABLE = 'No data available!';
export const LOADING_MSG = 'Loading...';
export const NETWORK_ERROR_MSG = 'Something went wrong!';
export const WIDGET_DATA_INFO = 'Last 90 days';
export const CODE_ANALYSIS = 'Code Analysis';
export const CODE_REPO = 'Code Repo';
export const PERFORMANCE = 'Performance';
export const DATA_FROM_FORTIFY = 'Data From Fortify';
export const ACTIVE_BUGS_BY_PRIORITY = 'Active Bugs By Priority';
export const AUTOMATION_TEST_SUMMARY = 'Automation Test Summary';
export const DATA_FROM_SONAR = 'Data From Sonar';
export const CODE_ISSUES_BY_REPO = 'Sonar Code Issues by Repository';
export const SONAR_CODE_SUMMARY = 'Sonar Code Summary';
export const CODE_ISSUES = 'Sonar Code Issues';
export const TOTAL_ISSUES = 'Total Issues';
export const CODE_ANALYSIS_UNIT_TEST_TITLE = 'Sonar Unit Test Coverage and Pass Percentage Over Time';
export const COVERAGE_PERCENTAGE = 'Coverage Percentage';
export const PASS_PERCENTAGE = 'Pass Percentage';
export const TECHNICAL_DEBT = 'Technical Debt';
export const BUGS_BY_TEAM = 'Bugs By Team';
export const OPEN_BUGS_BY_PRIORITY = 'Open Bugs By Priority';
export const DATA_FROM_QA_WIDGET = 'DATA FROM QA WIDGET'
export const BUGS_TREND = 'Bug Trend'
export const MANUAL_TEST_SUMMARY = 'Manual Test Summary'
export const MANUAL = 'manual'
export const AUTOMATIC = 'automatic'
export const PASSED_TITLE = 'Passed'
export const FAILED_TITLE = 'Failed'
export const OPEN_BUGS_LAST_90_DAYS = 'Total Open Bugs in last 90 days'
export const OPEN_TITLE = 'OPEN'
export const CLOSED_TITLE = 'CLOSED'
export const LEFT = 'left'
export const RESULTS = 'RESULTS'
export const CASES_RUN = 'Cases Run'
export const CASES_NOT_RUN = 'Cases Not Run'
export const ARCH = 'arch'
export const ROUND = 'round'
export const PASSED = 'passed'
export const FAILED = 'failed'
export const NUMBER = 'number'
export const OPEN = 'open'
export const CLOSED = 'closed'
export const NA = 'N/A'
export const NOT_APPLICABLE = 'NotApplicable'
export const LAST_EXECUTION_TIME = 'LAST EXECUTION TIME'
export const AVERAGE_TIME = 'AVERAGE TEST TIME (IN SEC)'
export const TOTAL_DURATION = 'totalDurationInMs'
export const START_TIME = 'startTime'
export const STORY_POINTS = 'storyPoints'
export const CURRENT_SPRINT = 'Current Sprint'
export const PREVIOUS_SPRINT = 'Previous Sprint'
export const DEFECT_DENSITY_BY_TEAM_MEMBER = 'Defect Density By Team Member'
export const PURPLE = 'purple'
export const BLUE = 'blue'
export const PULLS = 'Pulls'
export const COMMITS_TITLE = 'Commit'
export const WEEK_WISE_DATA = 'Week Wise Data'
export const DETAILS =  'Details'
export const PULL_TITLE = 'Pull Request'
export const TOP_CONTRIBUTORS = `Top Contributors`
export const STRING = 'string'
export const MY_SIZE = 'mysize'
export const TEST_PLAN = 'testPlanName'
export const TEST_PLAN_TITLE = 'TEST PLAN NAME'
export const EXECUTION_TIME = 'lastExecutionDate'
export const FIFTEEN = 15
export const THIRTY = 30
export const SIXTY = 60
export const NINETY = 90
export const FIFTEEN_STRING = "15"
export const THIRTY_STRING = "30"
export const SIXTY_STRING = "60"
export const NINETY_STRING = "90"
export const LAST_FIFTEEN_DAYS = "Last 15 Days"
export const LAST_THIRTY_DAYS = "Last 30 Days"
export const LAST_SIXTY_DAYS = "Last 60 Days"
export const LAST_NINETY_DAYS = "Last 90 Days"
export const LAST_ONE_EIGHTY_DAYS = "Last 180 Days"
export const SELECT_DATE_RANGE = "Select Date Range"
export const RANGE = "range"
export const DATE = "date"



export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';
export const CRITICAL = 'CRITICAL';
export const BLOCKERS = 'BLOCKERS';
export const DASHBOARD_B2B = 'B2B'
export const DASHBOARD_GNRM = 'GNRM'
export const DASHBOARD_RETAIL_360 = 'Retail360'
export const DASHBOARD_GUIDED_SELLING = 'Guided Selling'
export const DASHBOARD_WALMART_LUMINATE = 'Walmart Luminate';
export const DASHBOARD_WALMART_DSD = 'Walmart DSD'

export const CRITICAL_ISSUES = 'Critical';
export const BLOCKER_VIOLATION = 'blocker_violations';
export const CRITICAL_VIOLATION= 'critical_violations';

export const CRITICAL_ISSUES_TITLE = 'Critical Issues';
export const HIGH_ISSUES_TITLE = 'High Issues';
export const BLOCKER_VIOLATION_TITLE = 'Blocker Violations';
export const CRITICAL_VIOLATION_TITLE = 'Critical Violations';
export const CODE_VIOLATION_TITLE = 'Code Violation';
export const SECURITY_ISSUES_TITLE = 'Security Issues';
export const VELOCITY_STORY_POINTS_TITLE = 'Velocity (Story Points)';
export const COMMITMENT_MET_TITLE = 'Commitment Met';
export const AGGREGATED_ALL_TEAMS_MSG = 'AGGREGATED ACROSS ALL TEAMS IN THE PROGRAM';
export const BUG_SUMMARY_TITLE = 'Bug Summary';
export const TOTAL_DEPLOYEMENT_TITLE = 'Total Deployments';
export const ACROSS_ALL_ENVIRONMENT = '*ACROSS ALL ENVIRONMENTS';
export const OPEN_PULL_REQUEST_TITLE = 'Open Pull Requests';
export const INFRASTRUCTURE_COST_TITLE = 'Infrastructure Cost';
export const STATIC_ANALYSIS = 'StaticAnalysis';

export const FROM_SONAR = 'FROM SONAR';
export const FROM_FORTIFY = 'FROM FORTIFY';
export const MMMM = 'MMMM';
export const MM = 'MM';
export const MM_DD_YYYY = 'MM/DD/YYYY';
export const MM_DD_YY = 'mm/dd/yy';
export const MMMM_y = 'MMMM y';
export const DATE_FORMAT_MMM_YYYY = 'MMM-YYYY';
export const MMMM_YYYY = 'MMMM YYYY';
export const YYYY_MM = 'YYYY MM'
export const HH_mm = " HH:mm"
export const NA_ = "#NA";

export const VIEW_PROJECT_DETAILS = 'View Project Details';
export const PROJECT_DETAILS = 'Project Details';
export const CLOSE = 'Close';
export const NO_RESULTS = 'No results';
export const TEST_CASE_EXECUTED = 'Test Cases Executed'
export const MANUAL_TEST_PLAN_DETAIL = 'Manual Test Case Details'


export const DASHBOARD_TITLE_TEXT = 'A configurable DevOps dashboard that provides a near real-time consolidated view of entire application release pipeline';
export const TEAM_DASHBOARDS = 'Team Dashboards';
export const LEADER_BOARD = 'Leader Board';

// -----------CMDB-----------

export const ADD_CONFIGURE = 'Add Configure Item';
export const BUSINESS_SERVICE = 'Business Service';
export const COMPONENT = 'Component Name';
export const APPLICATION = 'Application Name';
export const APP_SERVICE_OWNER = 'App Service Owner';
export const BUSINESS_OWNER = 'Business Owner';
export const OWNER_DEPARTMENT = 'Owner Department';
export const CLOUD_COST_SERVICE = 'Cloud Cost Service';
export const MILESTONE_BOARD = 'Milestone Board';
export const PROGRAM_TAG = 'Program Tag';
export const FULL_STORY_TAG = 'Full Story Token'
export const APPD_TAG = 'App Dynamics Project Name'
export const SEARCHING = 'searching...'
export const NO_SUGGESTION_MSG= 'Sorry, suggestions could not be loaded.'
export const NOTIFICATION_RECIPIENTS = 'Notification Recipients:';
export const LEAD_TIME_TO_CHANGE_CONFIG = 'Lead Time To Change Configuration:'
export const LEAD_TIME_FIELD = "Lead Time To Change(in days)"
export const WORK_ITEM_TYPE = 'Work Item Type';
export const UAT_STATE = 'UAT State';
export const LEAD_TIME_INFO = 'Lead time to change is the time taken for user story or feature to complete development and become UAT ready (For User Stories, state transition is from In-progress to completed state. For Features, state transition is from In-progress to Deploying state).'
export const LEAD_TIME = 'Lead Time To Change'
export const DAY =' day'
export const DAYS = ' days'
export const MONTHLY = 'Monthly';
export const BIWEEKLY = 'Biweekly';
export const TOTAL_COST = 'Total Cost';
export const NO_BLOCKER_DATA_MSG = 'There are no blocker issues in this program!';
export const NO_CRITICAL_DATA_MSG = 'There are no critical issues in this program!';
export const APPLICATION_MESSAGE = 'Configuration item already exists, please provide a different Application Name.'
export const COMPONENT_MESSAGE = 'Configuration item already exists, please provide a different Component Name.'
export const APP = 'app'
export const LABEL_SERVICE = 'Service';
export const LABEL_SERVICE_OFFERING = 'Service Offering';
export const LABEL_CONFIGURATION_ITEM = 'Configuration Item';
export const LABEL_ASSIGNMENT_GROUP = 'Assignment Group';
export const LABEL_SERVICE_NOW = 'Service Now Configuration:';

// ---------Feature-Widget-Icon-Messages---------

export const COMMITMENT_MESSAGES = 'Percentage of user stories estimated on the first day of the sprint vs user stories closed in the sprint'
export const RIGHT_POSITION = 'right'
export const BELOW_POSITION = 'below'
export const NUMERATOR_MSG = "Number of stories in 'Closed' status in the current sprint"
export const DENOMINATOR_MSG = 'Number of stories estimated on the first day of the current sprint'
export const DEV_NUMERATOR_MSG = 'Number of stories completed on time in the  current sprint'
export const COMMON_DENOMINATOR_MSG = 'Number of stories estimated for current sprint'
export const QA_NUMERATOR_MSG = 'Number of stories closed on time in the current sprint'
export const VELOCITY_MSG = 'Aggregated number of story points for all the stories that are closed in the sprint'
export const DEV_COMMITMENT_MSG = "Percentage of stories that are 'Completed' before the deploy date"
export const QA_COMMITMENT_MSG = "Percentage of stories that are 'Closed' before the target date"
export const OTD_BY_TEAM_MEMBER_MSG = "This KPI shows whether a team member has adhered to his commits in the sprint. For Dev, a story is considered to be on-time if its is moved to “Completed” State before “Deploy Date”. For QA, a story is considered to be on-time if its is moved to “Closed” state before “Target Date”"
export const DEFECT_DENSITY_MSG = "This KPI tracks the quality of work done by the developer (number of bugs found per story point of work done by the developer). For all the closed stories in the sprint, we identify the developer who moved the story to “completed” state and list the defect density per developer. Bugs needs to be filed against the user story."
export const COMMON_BADGE_MSG = 'Deviation from configured goal of '
export const PERFORMANCE_COMMITMENTMET_MSG = 'Average commitment met % of all teams in the program where commitment met is defined as the percentage of stories estimated on the first day of sprint versus user stories closed in the sprint.'
export const PERFORMANCE_VELOCITY_MSG = 'Average velocity of all teams in the program where velocity is defined as the aggregated number of stories points for all stories that are closed in the sprint. '
export const COMMITMENT_MET_AND_VELOCITY= 'Commitment Met and Velocity'
export const TARGET = 'TARGET'
export const ON_TIME_DELIVERY_TOOLTIP_TEXT = 'Percentage of stories in the program that are closed before the target date'
export const DEV = 'DEV'
export const TARGET_S = "Target"
export const AVG_COMMITMENT = "Avg. Commitment"
export const COMMITMENT = 'COMMITMENT'
export const TARGETDATE = 'targetDate'
export const CLOSEDDATE = 'closedDate'
export const COMPLETEDDATE = 'completedDate'
export const DEPLOYDATE = 'deployDate'
export const DEPLOY_DATE = 'Deploy Date'
export const TARGET_DATE = 'Target Date'
export const MISSING_DATA = 'MISSING DATA'
export const DEFECT_DENSITY = 'Number of defects found per story point of work done'
export const DEFECT_DENSITY_BADGE_MSG = 'Deviation from configured goal of 0.08'
export const DEFECT_DENSITY_TITLE = 'Defect Density'
export const DEFECT_DENSITY_TREND = 'Defect Density Trend'
export const TEST_AUTOMATION = 'Number of test cases automated out of total test cases identified'
export const SE_METRICS = 'Software Engineering Metrics for DPA programs as updated manually in the shared excel sheet.'
export const TEST_AUTOMATION_TITLE = 'Test Automation'
export const MANUAL_TEST_TREND = 'Manual Test Plan Trend'
export const FUNCTIONAL_TEST_AUTOMATION_TITLE = 'Functional Test Automation'
export const REUSABILITY_TITLE = 'Reusability'
export const REUSABILITY_INFO = "Percentage of story points for with 'Global' tag against total story points worked upon in the selected calendar month"
export const APP_RESPONSE_INFO = 'Application Response Time available in Full Story for UI application or in App Dynamics for headless application for the selected calendar month'
export const TEST_AUTOMATION_TOOLTIP = 'Percentage of test cases that have been automated in the program in the current month'
export const TEST_CASES= 'Test Cases'
export const TEST_AUTOMATION_TREND_TITLE = 'Test Automation Trend'
export const AUTOMATION_EFFICIENCY_TREND_TITLE = 'Automation Efficiency Trend'
export const AUTOMATION = 'Automation'
export const TEST_CASE_FOUND = 'Test Case Found'
export const TEAM_NAME = 'ADO Board Name'
export const AUTOMATION_EFFICIENCY = 'Number of defects found via automation against total number of defects'
export const AUTOMATION_EFFICIENCY_TITLE = 'Automation Efficiency'
export const PROJECT_LATEST_SNAPSHOT_DATA = 'These KPIs are aggregated from the latest snapshot of all the sonar projects configured in the program. Latest snapshot dates are:'
export const MISSING_DATA_WARNING = 'Missing data affects the accuracy of the calculation'
export const STORY_TITLE = 'STORY TITLE'
export const STORY_ID = 'STORY ID'
export const STORY_POINTS_HEADER = 'STORY POINTS'
export const TIME_RANGE_MONTHLY = 'monthly'
export const MISSING_DATA_VELOCITY_TITLE = 'Missing data for Velocity'
export const MISSING_DATA_ONTIME_DELIVERY_TITLE = 'Missing data for On Time Delivery'
export const MISSING_DATA_TEST_AUTOMATION_TITLE = 'Missing data for On Test Automation'
export const MISSING_DATA_SUBTITLE = 'In order to correctly calculate this metric, the below stories require data'
export function getMissingTestAutomationValue(missingSprints) {
    if (missingSprints === 'current') return 'NO TEST CASE FOUND FOR CURRENT SPRINT';
    else if(missingSprints == 'previous') return 'NO TEST CASE FOUND FOR PREVIOUS SPRINT ';
    else if (missingSprints == 'both') return 'NO TEST CASE FOUND';
  };
export const APP_RESPONSE_TIME = 'App Response Time'
export const TEST_CASE_ID = 'TESTCASE ID'
export const TEST_CASE_NAME = 'TESTCASE NAME'
export const TEST_CASE_STATUS = 'TESTCASE STATUS'
export const BUG_ID = 'BUG ID'
export const COMPLETED_DATE = 'COMPLETED DATE'
export const LAST_UPDATE_DATE = 'LAST UPDATED DATE'
export const BUG_NAME = 'BUG NAME'
export const BUG_PRIORITY = 'BUG PRIORITY'
export const BUG_STATUS = 'BUG STATUS'
export const DEFECT_AGE = 'DEFECT AGE'
export const DEFECT_AGING = 'Defect Aging'
export const THRESHOLD_TEST = 'Threshold Test Automation'
export const THRESHOLD_AUTOMATION = 'Threshold Automation Efficiency'
export const AUTOMATED = 'Automated'
export const BLUE_COLOR_CODE = '#007eb5'
export const PINK_COLOR_CODE = '#a35078'
export const YELLOW_COLOR_CODE = '#ffa800'
export const PURPLE_COLOR_CODE = '#b992eb'
export const GREEN_COLOR_CODE = '#006A4E'
export const UNIT_TEST_COVERAGE = 'Unit Test Coverage'
export const PERCENTAGE_SIGN = '%'
export const ZERO_PERCENTAGE = '0%'
export const HYPHEN = '-'
export const NAN = 'NaN'
export const NAN_PERCENTAGE = 'NaN%'
export const ASTERIX = '*'
export const TIME_UNIT_MS = ' ms'
export const TIME_UNIT_HR = ' hr'
export const TIME_UNIT_HRS = ' hrs'
export const MONTHLY_LOWER_CASE = 'monthly'
export const COVERAGE = 'coverage'
export const DEFECT_AGE_INFO = 'Defect age is defined as the age of the bug since the time they are opened'
export const OPEN_CAMEL_CASE = 'Open'
export const CLOSED_CAMEL_CASE = 'Closed'
export const NOT_CLOSED = 'Not-Closed'
export const TEAM_NAME_TITLE = 'Team Name'
export const ONE_DAY = '1 Day'
export const TWO_THREE_DAYS = '2-3 Days'
export const FOUR_FIVE_DAYS = '4-5 Days'
export const FIVE_PLUS_DAYS = '5+ Days'
export const UNIT_TEST_COVERAGE_INFO_MESSAGE = 'Average unit test coverage from the latest run (snapshot) of all repos in Sonar'
//---------------------Deploy-Widget-----------------
export const PIPELINE_BUTTON_MSG = 'Add Pipeline'
export const DEPLOY_DROPDOWN_LABEL = 'Pipeline'
export const Msg_On_MaxLimit = '* Maximum 10 Boards can be added.'
export const DEPLOYMENT_FREQUENCY = 'Deployment Frequency'
export const DEPLOYMENT_FREQUENCY_INFO = 'Number of deployments completed in the current month based on ADO Milestone board'
export const PIPELINES = 'pipelines'
export const PROD_BRANCH = 'prodBranch'
export const HOTFIX_BRANCH = 'hotfixBranch'
export const PROD_ENV_NAME = 'prodEnvSuffix'
export const DEPLOY_PROD_BRANCH = 'deployProdBranch'
export const DEPLOY_HOTFIX_BRANCH = 'deployHotfixBranch'
export const DEPLOY_PROD_ENV_NAME = 'deployProdEnvSuffix'
export const DEPLOY_JOB = 'deployJob'

//---------------------API PARAMS--------------------
export const API_PARAM_PRODUCT_DASHBOARD_ID = 'productDashboardId';
export const API_PARAM_PERIOD = 'period';
export const API_PARAM_DATE_TYPE = 'dateType';
export const API_PARAM_TYPE = 'type';
export const API_PARAM_PAGE = 'page';
export const API_PARAM_SIZE = 'size';
export const API_PARAM_MONTH = 'month';
export const API_PARAM_YEAR = 'year';
export const API_CONST_STATIC_ANALYSIS = 'StaticAnalysis';
export const API_TYPE_GET = 'GET';
export const API_HEADER_NO_CACHED = 'ui-nocached-api';
export const API_CACHE_TTL = 'ui-cache-ttl';
//---------------------END----------------------------


export const VELOCITY = 'Velocity';
export const  THRESHOLD = 'Threshold';
export const CLOSED_DATE = 'Closed Date'
export const KPI_TRENDS = 'KPI Trends'
export const PERCENTAGE = 'Percentage'
export const SPRINT = 'Sprint'
export const KPI_TRENDS_TOOLTIP = 'Last 90 days KPI trend data';
export const TABLE_FIELD_TEAM_NAME = 'teamName';
export const TABLE_FIELD_FEATURE_ID = 'featureId'
export const TABLE_FIELD_NAME = 'name'
export const TABLE_FIELD_STATUS = 'status'
export const TABLE_FIELD_BUG_ID = 'bugId'
export const TABLE_FIELD_PRIORITY = 'priority'
//--------------------------------SE-Metrics---------------------------------
export const DSX_PROGRAM = 'DSX'
export const DASHBOARD_EMD = 'Engineering Maturity Dashboard'
export const DASHBOARD_CCT ='CCT'
export const DASHBOARD_Admin_Portal = 'Admin Portal'
export const DASHBOARD_Notifications_Framework = 'Notifications Framework'
export const DASHBOARD_Global_Design_System = 'Global Design System'
export const DASHBOARD_Receipt_Scanning = 'Receipt Scanning'
export const DASHBOARD_PSD = 'Product Success Dashboard'
export const DASHBOARD_AppRuntime = 'AppRuntime'
export const DASHBOARD_First_Mile_Customer_Support = 'First Mile Customer Support'
export const SINGLE_QUOTES = '';
export const DASHBOARD_Virtual_Control_Room = 'Virtual Control Room'
export const DASHBOARD_DX ='DX'
export const DASHBOARD_DTV = 'DTV'
export const DASHBOARD_D2C = 'D2C'
export const SE_METRICS_DIALOGUE_TEST = 'SE Metrics Definitions'
export const DEPLOYNMENT_FREQUENCY_TITLE = 'Deployment Frequency'
export const DEPLOYNMENT_FREQUENCY = 'Total number of deployments per month: Deployments are calculated from the milestone board in ADO. Those milestones whose ‘Target Date’ is in the selected calendar month and tagged with “Release” tag are considered for this KPI. Milestone state should be marked as ‘Closed’. If milestones for multiple programs are listed in the same board, then ‘<Program Name>’ tag should be added per milestone to calculate the deployment frequency of each program.';
export const AUTOMATION_TEXT_COVERAGE_TITLE = 'Automation Test Coverage'
export const AUTOMATION_TEXT_COVERAGE = 'Percentage of functional test cases that are automated against total number of test cases (automation candidates) in the program: The test cases are taken from the configured ADO board. The test cases that are automated needs to be enabled with “Automation Status1” flag. All the test cases which are automated from the starting of the program to last day of selected calendar month are cumulatively accounted in this KPI.'
export const UNIT_TEXT_COVERAGE_TITLE = 'Unit Test Coverage';
export const UNIT_TEXT_COVERAGE = 'Code coverage percentage from SonarQube for all the code repositories in the program: The code coverage from the latest run (snapshot) per repository is considered and averaged across all repos in the program to get the aggregated unit test coverage of the program for the selected calendar month.'
export const APP_RESPONSE_TIME_TITLE = 'Application Response Time'
export const APPLICATION_RESPONSE_TIME = 'Application response time of the program: Aggregated for all services in the program as available in AppDynamics for selected calendar month.'
export const REUSABILITY = 'Percentage of code (measured in story points) that can be reused globally in the program across all markets & customers: User Stories should have “Global” tag against each story that can be reused globally along with story points. For the sprints ending in the selected calendar month, this KPI calculates the percentage of story points having “Global” tag against the total story points of all stories of the selected month.'
export const AVAILABILITY_RESPONSE = "System Availability"
export const AVAILABILITY_TITLE = 'System Availability'
export const AVAILABILITY_TOOLTIP = 'Minimum System Availability across all the services of the program registered with AppDynamics for the current month.'
export const AVAILABILITY_TABLE_INFO = 'System Availability of the program: Minimum availability across all services configured in AppDynamics for the selected calendar month.'
export const LEAD_TIME_TITLE = 'Lead Time To Change';
export const LEAD_TIME_INFO_CONTENT = 'Lead time to change is the time taken for user story or feature to complete development and become UAT ready (For User Stories, state transition is from In-progress to completed state. For Features, state transition is from In-progress to Deploying state).';
export const CHANGE_FAIL_RATE = 'Change Fail Rate';
export const CHANGE_FAIL_RATE_INFO = 'Change Fail Rate is the percentage of production deployments that have failed in the current month and required a hot fix. Change Fail Rate is calculated as number of deployments from the hot fix branch divided by the total number of deployments from production branch & hot fix branch in the current month.';
export const CHANGE_FAIL_RATE_INFO_SHORT = 'Percentage of production deployments that failed in the current month and needed a hot fix from the hot fix branch.';
export const MEAN_TIME_TO_RESOLVE = 'Mean Time To Resolve';
export const MEAN_TIME_TO_RESOLVE_INFO = 'Mean Time To Resolve is the average time taken to resolve production incidents. MTTR is calculated for all production defects in Service Now (as per the service, service offering, configuration item and assignment group configuration) across all priority incidents that has been resolved in the current month.';
export const MEAN_TIME_TO_RESOLVE_INFO_SHORT = 'Average time to resolve all incidents in the current month from ServiceNow.';
export const CODE_ANALYSIS_SHORT = 'codeanalysis';
export const QA_COLLECTOR = 'qaCollector';
export const BUILD_SHORT = 'build';
export const AZURE_REPOS = 'AzureRepos';
export const DEPLOY_SHORT = 'deploy'
export const TEAM_LABEL = "Team";
export const WIDGETS = "Widgets";
export const HEADER = "HEADER";
export const DEPLOYMENT_FREQUENCY_KEY = 'deploymentFrequency'
export const TEST_AUTOMATION_KEY = 'testAutomation'
export const UNIT_TEST_COVERAGE_KEY = 'unitTestCoverage'
export const APP_RESPONSE_TIME_KEY = 'appResponseTime'
export const REUSABILITY_KEY = 'reusability'
export const LEAD_TIME_TO_CHANGE_KEY = 'leadTimeToChange'
export const CHANGE_FAIL_RATE_KEY = 'changeFailRate'
export const COMMERCIAL_AND_CONSUMER = 'commercialAndConsumer'
export const CONSUMER = 'consumer'
export const SUPPLY_CHAIN = 'supplyChain'
export const DSX = 'dsx'
export const COMMERCIAL_AND_CONSUMER_TITLE = 'Commercial & Consumer'
export const CONSUMER_TITLE = 'Consumer'
export const SUPPLY_CHAIN_TITLE = 'Supply Chain'
export const DSX_TITLE = 'DSX'
export const KEY_ART_END_USER = 'End User Experience|App|End User Response Time (ms)';
export const KEY_ART_OVERALL = 'Overall Application Performance|Average Response Time (ms)';

// -------------------CODE-ANALYSIS--------------------------------
export const PROJECT = 'project';
export const COMPONENTS = 'component';
export const LINE = 'line';
export const MESSAGE = 'message';
export const EFFORT = 'effort';
export const DEBT = 'debt';
export const AUTHOR = 'author';
export const STATUSES = 'status';
export  const severityMapping = {
  ['blocker violations']: 'BLOCKER',
  ['critical violations']: 'CRITICAL',
};
export const ADO_BOARD = 'adoBoard';
export const BUILD_PIPELINE = 'buildPipeline'
export const PROGRAM_SHORT = 'program';


//action button labels
export const SUBMIT_BUTTON_LABEL = "Submit"
export const UPDATE_BUTTON_LABEL = "Update"
export const CLOSE_BUTTON_LABEL = "Close"
export const EDIT_BUTTON_LABEL = "Edit"
export const GO_BACK_BUTTON_LABEL = "Back to Edit"