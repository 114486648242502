<div class="container">
  <div class="row mb-3">
    <div class="col-md-8">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
  </div>
  <div class="widget">
    <div class="divTable">
      <table class="divTableBody">
        <thead class="divTableRow">
          <th class="divTableCell"></th>
          <th class="divTableCell header-text">Today</th>
          <th class="divTableCell header-text">Last <br /> 7 Days</th>
          <th class="divTableCell header-text">{{lastFifteenDays}}</th>
          <th class="divTableCell header-text" *ngIf="selectDays===30 || selectDays===60 || selectDays===90">Last <br />
            {{selectDays}} Days</th>
        </thead>
        <ng-container>
          <tr class="divTableRow divTableRow100">
            <td class="divTableCell" id="">Repo</td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
          </tr>
          <tr class="divTableRow">
            <td class="divTableCell"><button id="commits" placement="right"
                ngbTooltip="A commit, or 'revision', is an individual change to a file (or set of files). When you make a commit to save your work, Git creates a unique ID (a.k.a. the 'SHA' or 'hash') that allows you to keep record of the specific changes committed along with who made them and when. Commits usually contain a commit message which is a brief description of what changes were made.">
                Commits</button>
            </td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <tr class="divTableRow">
            <td class="divTableCell"><button id="pull" placement="right"
                ngbTooltip="Pull requests are proposed changes to a repository submitted by a user and accepted or rejected by a repository's collaborators. Like issues, pull requests each have their own discussion forum.">
                Pull Requests (Closed)</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <tr class="divTableRow">
            <td class="divTableCell"><button id="pull" placement="right"
                ngbTooltip="Pull requests are proposed changes to a repository submitted by a user and accepted or rejected by a repository's collaborators. Like issues, pull requests each have their own discussion forum.">
                Pull Requests (Open)</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <!-- <tr class="divTableRow">
            <td class="divTableCell"><button id="pull" placement="right"
                ngbTooltip="Pull requests are proposed changes to a repository submitted by a user and accepted or rejected by a repository's collaborators. Like issues, pull requests each have their own discussion forum.">
                Pull Requests</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <tr class="divTableRow">
            <td class="divTableCell"><button id="issues" placement="right"
                ngbTooltip="Issues are suggested improvements, tasks or questions related to the repository. Issues can be created by anyone (for public repositories), and are moderated by repository collaborators. Each issue contains its own discussion thread. You can also categorize an issue with labels and assign it to someone.">
                Issues</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr> -->
          <tr class="divTableRow divTableRow100">
            <td class="divTableCell"><button id="" placement="right" ngbTooltip="">Developers</button></td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
            <td class="divTableCell"></td>
          </tr>
          <tr class="divTableRow">
            <!-- <div id="commuters" class="divTableCell"  data-toggle="tooltip" data-placement="right" title="The user who makes the commit"> -->
            <td class="divTableCell"><button id="commuters" placement="right"
                ngbTooltip="The user who makes the Commit">
                Commiters</button>
            </td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <tr class="divTableRow">
            <td class="divTableCell"><button id="contributors" placement="right"
                ngbTooltip="A contributor is someone who does not have collaborator access to a repository but has contributed to a project and had a pull request they opened merged into the repository.">
                Contributors</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr>
          <!-- <tr class="divTableRow">
            <td class="divTableCell"><button id="ideators" placement="right" 
              ngbTooltip="The user who creates Issues">
              Ideators</button></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
            <td class="divTableCell"><ng-template appChart></ng-template></td>
          </tr> -->
        </ng-container>
      </table>
    </div>
  </div>
</div>