<div class="container-fluid">
  <!-- <ng-container> -->
  <div style="margin-top: 2rem" class="repo-header">
    <div class="repo-header-filter">
      <label class="date-range-label">Date Range</label>
      <app-prime-calender [handleDateRange]="handleCalenderData()" [rangeDates]="rangeDates" 
        [allowedMinDate]="minDate" [allowedMaxDate]="maxDate"></app-prime-calender>
    </div>
    <div *ngIf="this.showCount" class="repo-header-filter">
      <span class="col-lg-12">
        <label class="radio-inline" for="PR"> <input
                class="radio-inline-input"
                id="PR" type="radio" name="PR"
                value="PR"  (change) ="handleChangeSearchOption(false)" [checked]="!configfetchCount">Pull Request</label>
        <label class="radio-inline" style="padding-left: 10px;"
            for="commit"> <input id="commit" type="radio"
                class="radio-inline-input"
                name="commit" 
                value="commit" (change) ="handleChangeSearchOption(true)" [checked]="configfetchCount">Commit 
        </label></span>
    </div>
    <div *ngIf="!this.showCount" class="repo-header-filter padding-top">
      <label class="filter-label" for="author">Author</label>
      <input type="text" class="author-input" placeholder="Select Author" list="author"
        (change)='onAuthorSelected($event)' name="author">
      <datalist id="author">
        <option *ngFor="let author of this.authors" value="{{author}}">{{author}} </option>
      </datalist>
    </div>
    <div [ngClass]="{'hide-div': !pullRequestStatuses?.length}" class="repo-header-filter padding-top">
      <label class="filter-label"> PR Status </label>
      <select class='select-option' (change)='onPullRequestStatusSelected()' [(ngModel)]="selectedPullRequestStatus">
        <option [ngValue]="undefined">Please Select</option>
        <option class="option" value="All" selected>All</option>
        <option class='option' *ngFor='let data of this.pullRequestStatuses' [value]="data">
          {{data}}</option>
      </select>
    </div>
  </div>
  <table *ngIf="!this.showCount" class="table table-striped">
    <thead>
      <tr>
        <th>Number</th>
        <th>Author</th>
        <th>Message</th>
        <th *ngIf="pullRequestStatuses?.length">Status</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of this.filterdData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
        <td class="divTableCell" ><a href="{{item.urlscm}}" target="_blank">{{item?.number}}</a></td>
        <td class="divTableCell" >{{item?.author}}</td>
        <td  class="text-ellipsis" container="body" tooltipClass="message-tooltip" ngbTooltip="{{item.message}}">{{item?.message}}</td>
        <td *ngIf="!!item.pullstate">{{item?.pullstate}}</td>
        <td class="divTableCell" >{{item?.time | date:'medium':'UTC' }}</td>
      </tr>
    </tbody>
  </table>
  <table *ngIf="this.showCount" class="table table-striped">
    <thead>
      <tr>
        <th class="text-capitalize">Email</th>
        <th>Count</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of this.countData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
        <td  class="contributors-count-row text-capitalize">{{item?._id}}</td>
        <td  class="contributors-count-row">{{item?.count}}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!this.showCount" class="d-flex justify-content-center container-wrapper">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="20">
    </ngb-pagination>
  </div>
  <div *ngIf="this.showCount" class="d-flex justify-content-center container-wrapper">
    <ngb-pagination [collectionSize]="countCollectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="20">
    </ngb-pagination>
  </div>
</div>