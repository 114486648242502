import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule,Injector } from '@angular/core';

// 3rd Party imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuiModalModule } from '@giomamaladze/ng2-semantic-ui';
import { APP_BASE_HREF } from '@angular/common';
// App imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbActionsModule,
  NbUserModule,
  NbSearchModule,
  NbIconModule
} from '@nebular/theme';
import {
  OKTA_CONFIG,
  OktaAuthModule,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { GlobalErrorHandler } from './app.error.handler';
import { SsoAuthGuard } from './user/sso/sso.authguard';
import { CommonModule } from '@angular/common';
import { RotationChartComponent } from './shared/charts/rotation/rotation-chart.component';
import { environment } from '../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';


import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';
import {ContextMenuModule} from 'primeng/contextmenu';
import { SEMetricsComponent } from './se-metrics/se-metrics.component';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCallsInterceptor } from './HttpCallsInterceptor';
import { SeMetricsManualDataFormComponent } from './se-metrics/se-metrics-manual-data-form/se-metrics-manual-data-form.component';
import { CostComponent } from './cost/cost.component';


@NgModule({
  declarations: [
    AppComponent,RotationChartComponent, SEMetricsComponent, SeMetricsManualDataFormComponent, CostComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    NgbModule,
    SharedModule,
    NbThemeModule.forRoot({name: 'dark'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbActionsModule,
    NbUserModule,
    NbSearchModule,
    NbIconModule,
    OktaAuthModule,
    SuiModalModule,
    AccordionModule,
    AutoCompleteModule,
    TreeModule,
    TreeTableModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    FormsModule,
    TooltipModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }, SsoAuthGuard,
    { 
      provide: OKTA_CONFIG, 
      useFactory: () => {
        const oktaAuth = new OktaAuth(environment.config);
        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const triggerLogin = async () => {
              await oktaAuth.signInWithRedirect();
            };
            if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
              // App initialization stage
              triggerLogin();
            } else {
            }
          }  
        }
      }
    },{ provide: APP_BASE_HREF, useValue: environment.config.appBaseHref },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCallsInterceptor,
      multi: true
    }
   ],
  bootstrap: [AppComponent],
  exports: [CommonModule, RotationChartComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
