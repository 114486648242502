import { Component,EventEmitter,Input, OnInit, Output } from '@angular/core';
import { FeatureWidgetComponentApi } from "../../../widget_modules/feature/feature-widget/feature-widget.component"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableViewComponent } from '../../tables/table-view/table-view.component';
import { BUG_ID, BUG_NAME, BUG_PRIORITY, BUG_STATUS, DEFECT_AGE, DEFECT_AGING, NO_DATA_MSG, TEAM_NAME_TITLE } from '../../app-constants';

@Component({
    selector: 'app-tree-table',
    templateUrl: './tree-table.component.html',
    styleUrls: ['./tree-table.component.scss']
})
export class TreeTableComponent implements OnInit {
    @Input() files: any;
    @Input() loading: any;
    @Input() cols: any;
    @Input() disableFilter:boolean;
    @Input() showToolTip: boolean;
    @Input() showCategoryWithToolTip: boolean;
    @Input() fetchTreeData: FeatureWidgetComponentApi

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {
    }

    callFetchNode(value) {
      this.fetchTreeData.callParentMethod(value)
    } 

    onColumnClick(col,rowData) {
      const tableData = []
      const tableColumns = [
        {field : 'bugId', header : BUG_ID},
        {field : 'teamName',header : TEAM_NAME_TITLE},
        {field : 'name', header : BUG_NAME},
        {field: 'priority', header : BUG_PRIORITY},
        {field: 'status', header : BUG_STATUS},
        {field: 'defectAge', header : DEFECT_AGE}
      ];
      rowData?.bugs?.forEach(ele => {
        ele?.bugs?.forEach(val=> {
          if(col.header === ele.name && col.header != undefined){
            let model = { bugId : {
              name: val?.bugNumber,
              url : val?.url
            },
            teamName : val?.teamName,
            name: val?.name,
            priority : val?.priority,
            status : val?.status,
            defectAge : col?.header
          }
          tableData.push(model);
          }
          if(col.header === 'Total' && ele.name != undefined){
            let model = { bugId : {
              name: val?.bugNumber,
              url : val?.url
            },
            teamName : val?.teamName,
            name: val?.name,
            priority : val?.priority,
            status : val?.status,
            defectAge : ele?.name
          }
          tableData.push(model);
          }
        });
      });  
      const formattedData = {
        tableData: [...tableData],
        tableColumns : tableData?.length ? [...tableColumns] : [], 
        loadingMessage: tableData?.length ? '' : NO_DATA_MSG ,
        scrollable : true , scrollHeight : '600px',
        title : DEFECT_AGING,
      }
      const modalRef = this.modalService.open(TableViewComponent,{ windowClass: 'defect-aging-chart'})
      modalRef.componentInstance.data = formattedData;
      modalRef.componentInstance.title = formattedData?.title;
      modalRef.componentInstance.modal = true;
    } 
}