import { Component, OnInit } from '@angular/core';
import {BaseTemplateComponent} from '../../../shared/templates/base-template/base-template.component';
import { OVERVIEW } from 'src/app/shared/app-constants';
import { ProductDataService } from 'src/app/product_widget_modules/product-data.service';

@Component({
  selector: 'app-widget-template',
  templateUrl: './widget-template.component.html',
  styleUrls: ['./widget-template.component.scss']
})
export class WidgetTemplateComponent extends BaseTemplateComponent implements OnInit {
  constructor(private productDataService: ProductDataService){
    super();
    productDataService.setSelectedTab(OVERVIEW);
  }
  ngOnInit() {
   
  }

  onTabSelection(event){
    this.productDataService.setSelectedTab(event?.tabTitle);
  }

}
