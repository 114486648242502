<div class="widget dark-chart-theme" #areacontainerRef>
    <div class="chart-heading">
        {{title}}
    </div>
    <div *ngIf="displayMessages?.length" class="info-view">
        <div class="info-row" *ngFor="let msg of displayMessages">
            <span class="info-title" [ngbTooltip] = "msg.message" placement="bottom">{{msg.title}}:</span>
            <span class="info-msg">{{msg.count}}</span>
        </div>
        <div class="info-row">
            <span class="info-title">ADO Board: </span>
            <span class = "info-msg">{{adoBoardValue}}</span>
        </div>
    </div>
    <ngx-charts-bar-vertical-2d [view]="[500, 300]" [scheme]="colorScheme" [yAxisTickFormatting]="yAxisFormatter" [yAxisTicks]="data.yAxisTicks"
        [results]="bugsData" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [animations]='true' [roundEdges]="true"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [barPadding]="'4'" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [groupPadding]="20" [timeline]="timeline" [legend]="showLegend" [legendPosition]="'right'" (select)="onSelect($event)">
    </ngx-charts-bar-vertical-2d>
</div>