<div [ngClass]="{'chart-heading': !data?.clickableHeader}">
  {{title}}
  <div [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView()">
    <span class="storyPoints pull-right clickable">
      Details
      <span class="fa fa-arrow-right" style="margin-left: 0.5rem;"></span></span>
  </div>
</div>

<div *ngIf="!hidechart">
  <ngb-tab *ngFor="let indivItem of data?.items">
    <ngb-accordion [destroyOnHide]='false' [closeOthers]="true" (panelChange)='panelShadow($event, shadow)' #shadow>
      <ngb-panel id="{{item?.key}}" *ngFor="let item of indivItem[0].statustypeName[0] | keyvalue">
        <ng-template ngbPanelTitle>
          <span>{{item.key}}</span>
        </ng-template>
        <ng-template ngbPanelContent>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Status Type</th>
                <th scope="col">Total</th>
                <th scope="col">Estimates</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let innerkey of item.value | keyvalue">
                <td>{{innerkey.key}}</td>
                <td>{{innerkey.value[0]}}</td>
                <td>{{innerkey.value[1]}}</td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ngb-tab>
</div>
