import { Component, Input, Type } from '@angular/core';

@Component({
  selector: 'app-widget-view-renderer',
  template: '<ng-container *ngComponentOutlet="widgetView;"></ng-container>',
  styleUrls: ['./widget-view-renderer.component.scss']
})
export class WidgetViewRendererComponent {
  @Input() widgetView: Type<any>;
  @Input() title: string;
  @Input() status: string;
}
