import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {CollectorItemRoutingModule} from './collector-item-routing.module';
import {CollectorItemService} from './collector-item.service';
import {NbCardModule, NbInputModule} from '@nebular/theme';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CollectorItemRoutingModule.components,
  ],
  imports: [
    CollectorItemRoutingModule,
    CommonModule,
    NgbModule,
    NbInputModule,
    NbCardModule,
    ReactiveFormsModule
  ],
  providers: [
    CollectorItemRoutingModule.components,
    CollectorItemService,
    NgbActiveModal
  ],
  exports: []
})
export class CollectorItemModule { }
