<div class="table-view-container">
    <div class="header-view">
        <div class="title-section">
            <span class="title" *ngIf="title">{{title}}</span>
            <span class="sub-title" *ngIf="data?.subTitle">{{data?.subTitle}}</span>
        </div>
    </div>
    <p-table [columns]="data.tableColumns" [value]="data.tableData" [scrollable]="data.scrollable || false"
        [scrollHeight]="data.scrollable ? data.scrollHeight || DEFAULT_SCROLL_HEIGHT : 'false'" [columnResizeMode]="expand"[customSort]="data.sortable || false"
        (sortFunction)="data.sortable ? handleSortFunction($event) : null"
        [tableStyle]="data.tableStyles" *ngIf="data?.tableColumns && data?.tableData?.length else msg_block">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngIf= "data?.sortable else notSortable" >                                                   
                    <th *ngFor="let col of columns; let i = index" [ngStyle]="data.tableHeaderColStyles" [id]="i"
                        [class.text-center]="col?.align === 'center'" [pSortableColumn]="col.field">
                        <span *ngIf="col.header?.twoLines else singleLine">{{ col.header.textHeaders[0] }}<br>{{ col.header.textHeaders[1] }}</span> 
                        <ng-template #singleLine>
                            <span>{{ col.header }}</span>
                        </ng-template>
                        <ng-container>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </ng-container>
                    </th>
                </ng-container>
                <ng-template #notSortable>
                    <th *ngFor="let col of columns; let i = index" [ngStyle]="data.tableHeaderColStyles" [id]="i"
                        [class.text-center]="col?.align === 'center'">
                        {{ col.header }} 
                    </th>
            </ng-template>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td [ngStyle]="data.tableBodyColStyles" *ngFor="let col of columns"
                    [class.text-center]="col?.align ==='center'">
                    <ng-container *ngIf="rowData[col.field]?.period">
                        <a href="javascript:void(0)" (click)="redirectionToProductDashboard(rowData[col.field]?.id,  rowData[col.field]?.name)">
                            {{ rowData[col.field].name }}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="rowData[col.field]?.url">
                        <a href="{{rowData[col.field]?.url}}" target="_blank">
                            {{ rowData[col.field].name }}
                        </a>
                    </ng-container>    
                        <ng-container *ngIf="!rowData[col.field]?.period && !rowData[col.field]?.url">
                        <span *ngIf="col.field === SNAPSHOT">{{ rowData[col.field].name }} </span>
                        <span *ngIf="data?.rowType && col.field !== SNAPSHOT">
                            <span *ngIf="col.field !== REPO && col.field !== TYPE"
                                [class]="compareTargettoThreshold(rowData[col.field]) ? 'text-green':'text-red'">{{
                                rowData[col.field] }}</span>
                            <span *ngIf="col.field === REPO || col.field === TYPE">{{ rowData[col.field] }} </span>
                        </span>
                        <span *ngIf="data?.lastFieldColor && col.field !== SNAPSHOT">
                            <span *ngIf="col.field !== REPO && col.field !== TYPE" [class]="isStoryPointsData(col.field) ? 'text-red':''">{{ rowData[col.field] }}</span>
                            <span *ngIf="col.field === REPO || col.field === TYPE">{{ rowData[col.field] }}</span>
                        </span>
                        <span *ngIf="!data?.rowType && !data?.lastFieldColor && col.field !== SNAPSHOT">
                            <ng-container *ngIf="!rowData.tooltip">
                                <span *ngIf="!rowData[col.field]?.dateFlag" [class]="rowData[col.field] == 'No' ? 'text-red':''"> {{ rowData[col.field] }} </span>
                                <span *ngIf="rowData[col.field]?.dateFlag"
                                    [class]="rowData[col.field]?.dateFlag ? 'text-red':''"> {{ rowData[col.field]?.name
                                    }} </span>
                            </ng-container>
                            <ng-container *ngIf="rowData.tooltip">
                                <span *ngIf="col.header == 'TEAM'" [title]="rowData[col.field]">{{ rowData[col.field] }}</span>
                                <span *ngIf="!rowData[col.field]?.dateFlag && col.header == 'NAME'"
                                    [title]="rowData.tooltip"> {{ rowData[col.field] }} </span>
                                <span *ngIf="!rowData[col.field]?.dateFlag && col.header != 'NAME' && col.header != 'TEAM'"> {{
                                    rowData[col.field] }} </span>
                                <span *ngIf="rowData[col.field]?.dateFlag" [title]="rowData.tooltip"
                                    [class]="rowData[col.field]?.dateFlag ? 'text-red':''"> {{ rowData[col.field]?.name
                                    }} </span>        
                            </ng-container>
                        </span>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #msg_block>
        <span class="msg_block">{{data?.loadingMessage}}</span>
    </ng-template>
    <div class="button-section" *ngIf="modal">
        <button type="button" class="close-button" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">{{CLOSE}}</span>
        </button>
    </div>
</div>