<div class="delivery-title-box">
  <div class="sprint-title">{{ data?.title }}</div>
  <select
    style="-webkit-appearance: none; -moz-appearance: none; appearance: none"
    *ngIf="data?.showChartDurationOptions || false"
  >
    <option
      *ngFor="let option of data.chartDurationOptions"
      value="option.value"
    >
      {{ option.title }}
    </option>
  </select>
</div>
<ng-container
  *ngIf="data?.chartData && data?.chartData.length > 0; else elseBlock"
>
  <div class="trend-box" *ngIf="data?.customLegend">
    <ul class="trend-list">
      <li *ngFor="let label of data.legendLabels; let i = index">
        <span [style.backgroundColor]="label.color"></span>
        <span>{{ label.name }}</span>
      </li>
    </ul>
  </div>
  <div
    class="delivery-time-trend widget dark-chart-theme"
    style="display: grid;" [ngStyle]="{'height': data?.height ? data?.height : '400px' }"
  >
    <ngx-charts-line-chart
      [scheme]="data?.colorScheme"
      [legend]="data?.legend || false"
      [tooltipDisabled]="data?.tooltipDisabled || false"
      [showXAxisLabel]="data?.showXAxisLabel || false"
      [showYAxisLabel]="data?.showYAxisLabel || false"
      [xAxis]="data?.xAxis || false"
      [yAxis]="data?.yAxis || false"
      [showGridLines]="data?.showGridLines || true"
      [xAxisLabel]="data?.xAxisLabel || ''"
      [yAxisLabel]="data?.yAxisLabel || ''"
      [timeline]="data?.timeline || false"
      [results]="data?.chartData"
      [legendPosition]="data?.legendPosition || 'below'"
      [yScaleMax]="data?.yScaleMax || 100"
      [showRefLines]="data?.showRefLines || false"
      [showRefLabels]="data?.showRefLabels || false"
      [referenceLines]="data?.referenceLine"
      (select)="onGraphPointSelect($event)"
      [view]="data?.view"
    >
      <ng-template #tooltipTemplate let-model="model">
        <h6 class="mt-2">{{ model.tooltipName }}</h6>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</ng-container>
<ng-template #elseBlock>
  <div class="msg-block">
    {{ data?.loadingMessage }}
  </div>
</ng-template>