<div class="mt-2" *ngIf="isPositive ">
    <span *ngIf="!check" class="p-2 badge increase" [pTooltip]="tooltipMessage" [tooltipPosition]="tooltipPosition">{{deviation+'% '}}<i class="pi pi-arrow-up-right" ></i>
    </span>
    <span *ngIf="!defectType && check" class="p-2 badge decrease"  [pTooltip]="tooltipMessage" [tooltipPosition]="tooltipPosition">{{deviation+'% '}}<i class="pi pi-arrow-up-right" style="font-size: 0.7rem"></i>
    </span>
</div>

<div class="mt-2" *ngIf="!isPositive" >
    <span  *ngIf="!check" class="p-2 badge decrease"  [pTooltip]="tooltipMessage" [tooltipPosition]="tooltipPosition">{{deviation+'% '}}<i class="pi pi-arrow-down-right" style="font-size: 0.7rem"></i>
         </span>
    <span *ngIf="defectType && check" class="p-2 badge increase"  [pTooltip]="tooltipMessage" [tooltipPosition]="tooltipPosition">{{deviation+'% '}}<i class="pi pi-arrow-down-right" style="font-size: 0.7rem"></i>
    </span>
</div>
<ng-template #msg_block *ngIf="!data || !data.chartData">
<span class="msg_block">{{data?.loadingMessage}}</span>
</ng-template>