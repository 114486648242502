import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: ['./combo-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComboChartComponent extends ChartComponent {
  public readableDuration: string = '-- : -- : --'; // Initialize with default value
  public data: any[] = []; // Initialize data as an empty array

  constructor() {
    super();
  }

  @Input()
  set detailData(data: any) {
    if (data && data.data) {
      this.data = Array.isArray(data.data) ? data.data : [data.data];
    } else {
      this.data = [];
    }

    // Ensure data[0] is valid
    if (this.data.length > 0) {
      const firstItem = this.data[0];
      if (firstItem && firstItem.stages) {
        firstItem.stages.forEach(stage => {
          if (stage.error && stage.error.message) {
            stage.error.message = `${stage.error.message.substring(0, 150)} ...`;
          }
        });
      }

      this.readableDuration = firstItem && firstItem.duration
        ? this.convertToReadable(firstItem.duration)
        : '-- : -- : --';
    } else {
      this.readableDuration = '-- : -- : --';
    }
  }

  // Converts build duration to HH:mm:ss format
  convertToReadable(timeInMiliseconds: number): string {
    const hours = Math.floor(timeInMiliseconds / 1000 / 60 / 60);
    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;

    const minutes = Math.floor((timeInMiliseconds / 1000 / 60 / 60 - hours) * 60);
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const seconds = Math.floor(((timeInMiliseconds / 1000 / 60 / 60 - hours) * 60 - minutes) * 60);
    const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${hoursString}:${minutesString}:${secondsString}`;
  }

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  showGridLines = false;
  innerPadding = '2%';
  animations: boolean = true;
  trimYAxisTicks = true;
  maxYAxisTickLength = 8;
  noBarWhenZero = true;
  yAxisTickFormatting: (val: number) => string = this.formatToMinute;
  xAxisTickFormatting: (val: Date) => string = this.formatToDayAndMonth;
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';

  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#3f51b5', '#a8385d', '#00bfa5']
  };
  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  formatInteger(val: number): string {
    return val.toFixed(0);
  }

  formatToMinute(val: number): string {
    const minutes = Math.floor(val / 60000);
    const seconds = ((val % 60000) / 1000);
    return (seconds === 60 ? (minutes + 1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds.toFixed(0));
  }

  formatToDayAndMonth(val: Date): string {
    return val ? `${val.getMonth() + 1}/${val.getDate()}` : '';
  }

  ngOnInit() {
  }
}
