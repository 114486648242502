<div class="widget dark-chart-theme" #linecontainerRef>
  <div class="chart-heading">
    {{title}}
  </div>
  <app-line-and-bar-chart
    [view]="[linecontainerRef.offsetWidth, 300]"     
    [scheme]="comboBarScheme"
    [colorSchemeLine]="lineChartScheme"
    [results]="data[0]"
    [animations]="animations"
    [lineChart]="data[1]"
    [gradient]="gradient"
    [legend]="showLegend"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [yAxisTickFormatting]="yAxisTickFormatting"
    [xAxisTickFormatting]="xAxisTickFormatting"
    [maxYAxisTickLength]="7"
    [noBarWhenZero]="noBarWhenZero"
    [showGridLines]="showGridLines">
    <ng-template #seriesTooltipTemplate let-model="model">
      <xhtml:div class="area-tooltip-container">
        <xhtml:div *ngFor="let tooltipItem of model" class="tooltip-item">
          <span class="tooltip-item-color" [style.background-color]="tooltipItem.color">
          </span>
          <!-- {{ tooltipItem.series}}: {{ tooltipItem.value | minutes}}m -->
          {{ tooltipItem.series}}: {{ tooltipItem.value | date: 'mm:ss':'UTC'}}
        </xhtml:div>
      </xhtml:div>
    </ng-template>
  </app-line-and-bar-chart>
</div>
