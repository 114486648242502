<div class="app-pipeline-chart">
    <header class="clearfix">
        <div class="row">
        <div class="offset-xs-3 col-xs-9 offset-md-2 col-md-10">
            <div class="row">
                <span class="pipeline-environment-header col-{{colGridSize}}" *ngFor="let item of data.key" [ngClass]="item.deployed ? '' : 'down'">
                    <a href="{{item.url}}" target="_blank">
                    <span>
                        {{item.viewname?.toUpperCase()}}
                    </span>
                </a>
                </span>
            </div>
        </div>
    </div>
    </header>
    <section>
        <div class="pipeline-row row clearfix" *ngFor="let item of data.value">
            <div class="col-xs-3 col-md-2">
                <div class="row">
                    <div class="pipeline-unit-name dash-overflow w-100">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="col-xs-9 col-md-10">
                <div class="row">
                    <div class="col-{{colGridSize}}" *ngFor="let ele of data.key">
                        <div class="pipeline-data-cell">
                            <span *ngIf="!item[ele.name+'deployed']" >
                                <app-dash-status [statusText]="'!'" [status]="item[ele.name+'deployed'] ? '' : '4'"></app-dash-status>
                            </span>
                            <span class="d-inline-block">
                            <div class="version">
                                {{item[ele.name+"version"]}}
                            </div>
                            <div class="text-standard-date">
                                {{item[ele.name+"deployTime"]}}
                            </div>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>