import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private multipleSelectionSource = new BehaviorSubject<any[]>([]);
  multipleSelection$ = this.multipleSelectionSource.asObservable();

  private updateDateSelection = new BehaviorSubject<number>(0);
  updateDate$ = this.updateDateSelection.asObservable();

  updateMultipleSelection(newSelection: any[]): void {
    this.multipleSelectionSource.next(newSelection);
  }
  updateDate(newSelection: number): void{
    this.updateDateSelection.next(newSelection);
  }
}
