import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CodeAnalysisDataSharedService {

    constructor() { }

private codeAnalysisViolationsData = new Subject<void>();

    codeAnalysisViolationsData$ = this.codeAnalysisViolationsData.asObservable();

    triggerCodeAnalysisViolationsData(val) {
        this.codeAnalysisViolationsData.next(val);         
    
    }
}