<div class="donut-chart-view">
    <span class="title">{{title}}</span>
    <div class="chart-container" *ngIf="data && data.chartData?.length else msg_block">
        <ngx-charts-advanced-pie-chart
            [view]="data.view"
            [scheme]="colorScheme"
            [results]="data.chartData"
            [gradient]="data.gradient || false"
            [tooltipDisabled]="data.tooltipDisabled || false"
            [valueFormatting]="data.valueFormatting"
            [nameFormatting]="data.nameFormatting"
            [percentageFormatting]="data.percentageFormatting"
            [labelFormatting]="data.labelFormatting"   
        ></ngx-charts-advanced-pie-chart>
        <div class="details-container">
            <div class="status-list-view">
                <span class="sub-title" *ngIf="data.subTitle">{{data.subTitle}}</span>
                <div class="status-row" *ngFor="let status of data.chartData; let i = index;">
                    <div class="color-circle" [ngStyle]="{backgroundColor: colorScheme.domain[i]}"></div>
                    <ng-container *ngIf="data?.labelTooltip; else elseBlock">
                        <span class="label-small" [title]="status.name">
                            <a href={{status.url}} target="_blank" *ngIf="status.url?.length; else templateVal">{{status.name}}</a>
                            <ng-template #templateVal>{{status.name}}</ng-template>
                        </span>
                    </ng-container>
                <ng-template #elseBlock>
                    <span class="label-small">
                        <a href={{status.url}} target="_blank" *ngIf="status.url?.length; else templateVal">{{status.name}}</a>
                        <ng-template #templateVal>{{status.name}}</ng-template>
                    </span>
                </ng-template>
                </div>
            </div>
            <div class="details-view" *ngIf="data.showDetailSection">
                <div class="details-item" *ngFor="let item of data.details">
                    <span class="label">{{item.name}}</span>
                    <span class="count-label">{{item.value}}</span>
                    <div class="tooltip-view bottom" *ngIf="item.name!=='Total'">
                        <div class="tooltip-row" *ngFor="let statusInfo of data.tooltipDetails">
                            <div class="status-details" *ngFor="let status of statusInfo.statusDetail">
                                <div class="status-row" *ngIf="status.name===item.name">
                                    <span>{{statusInfo.categoryName}}</span>
                                    <span>{{status.value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #msg_block>
        <span class="msg_block">{{data?.loadingMessage}}</span>
    </ng-template>
</div>
