<div class="widget dark-chart-theme">
    <div class="chart-heading">
        {{title}}
    </div>
    <div id="pie-chart-parent">
        <ngx-charts-number-card
        [view]="[200,250]"
        [scheme]="colorScheme"
        emptyColor="#1e222e"
        [results]="data"
        [innerPadding]="10"
        [textColor]="textColor"
        [animations]='true'
        (select)="onSelect($event)"
        >
        </ngx-charts-number-card>
    </div>
</div>