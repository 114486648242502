<div class="widget dark-chart-theme" id="{{title?.trim()}}">
  <div id="title" [ngClass]="{'clickable-title': data?.clickableHeader, 'chart-heading': !data?.clickableHeader}"
    (click)="openHeaderView(data, title)">
    {{title}}
    <ng-container *ngIf="data?.clickableHeader">
      <span class="header-arrow fa fa-arrow-right"></span>
    </ng-container>
  </div>
  <div *ngIf="data?.items">
    <div class="widget-body">
      <table class="table table-hover" [ngClass]="{'table-hover': data?.clickableContent}">
        <tr class="dash-row clickable" *ngFor="let item of data?.items"
          [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView(item)">
          <td id="{{item.status}}" *ngIf="item.status">
            <app-dash-status [statusText]="item.statusText" [status]="item.status"></app-dash-status>
          </td>
          <td class="width30per" *ngIf="item.title && !item.devText"><span class="list-item-title">{{item.title}}</span></td>
          <td class="width30per" *ngIf="item.title && item.devText"><span class="list-item-title">{{item.name}}</span></td>
          <td class="width30per" *ngIf="item.title && item.devText"><span class="list-item-title">{{item.devText}}</span></td>
          <ng-container *ngIf="item?.deploySuccess>=0 && item?.deployFailure>=0">
            <td>
              <span id="deploypass" class="fa fa-arrow-up">{{item.deploySuccess}}</span>
              <span id="deployfail" class="fa fa-arrow-down">{{item.deployFailure}}</span>
            </td>
          </ng-container>
          <ng-container *ngIf="item.subtitles">
            <ng-container *ngFor="let subtitle of item.subtitles">
              <!-- gettimesubtitle :: {{isDate(subtitle)}} :: {{subtitle}} -->
              <td>
                <ng-container *ngIf="isDate(subtitle); else elseBlock">
                  <span class="list-item-subtitle">{{subtitle.getTime() | timeAgo}}</span>
                </ng-container>
                <ng-template #elseBlock>
                  <ng-container *ngIf="(item.buildDurationDays)">
                    <span class="list-item-subtitle"> {{item.buildDurationDays}}</span>
                  </ng-container>
                  <ng-container *ngIf="!(item.buildDurationDays)">
                    <span class="list-item-subtitle"> {{subtitle?.toString()}}</span>
                  </ng-container>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="data?.clickableContent">
            <td><span class="list-item-chevron pull-right fa fa-lg fa-angle-right"></span></td>
          </ng-container>
        </tr>
      </table>
    </div>
  </div>
</div>