<div class="widget dark-chart-theme">
  <div class="chart-heading">
    {{title}}
  </div>
  <ngx-charts-number-card
    [view]="[200,350]"
    [scheme]="colorScheme"
    cardColor="#2d3347"
    emptyColor="#1e222e"
    [results]="data"
    [innerPadding]="10"
    [textColor]="textColor"
    [animations]='true'>
  </ngx-charts-number-card>
</div>