<div class="apex-horizontal-chart-view">
    <span class="title" *ngIf="title">{{title}}</span>
    <div class="chart-container" *ngIf="data && data.series?.length else msg_block">
      <apx-chart
              [series]="data.series"
              [chart]="data.chart || DEFAULT_CHART_TYPE"
              [dataLabels]="data.dataLabels || DEFAULT_DATA_LABELS_CONFIG"
              [plotOptions]="data.plotOptions || DEFAULT_PLOT_OPTION_CONFIG"
              [grid]="data.grid || SHOW_FALSE"
              [tooltip]="data.tooltip || SHOW_FALSE"
              [legend]="data.legend || SHOW_FALSE"
              [colors]="CHART_COLORS"
              [xaxis]="data.xaxis"
              [yaxis]="data.yaxis"
        ></apx-chart>
    </div>
    <ng-template #msg_block>
      <span class="msg_block">{{data?.loadingMessage}}</span>
    </ng-template>
  </div>