import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CollectorItemViewerComponent } from './collector-item-viewer/collector-item-viewer.component';
import {CollectorItemDetailsComponent} from './collector-item-viewer/collector-item-details/collector-item-details.component';
import {CollectorRefreshComponent} from './collector-item-viewer/collector-refresh/collector-refresh.component';
const routes: Routes = [
  { path: 'viewer', component: CollectorItemViewerComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollectorItemRoutingModule {
  static components = [CollectorItemViewerComponent, CollectorItemDetailsComponent, CollectorRefreshComponent];
}
