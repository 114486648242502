import { Component, Input, OnInit } from '@angular/core';
import { CHART_DEFAULT_COLORS } from '../../app-utility';
import { IDonutChartData } from '../chart-interfaces';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit{
  
  @Input() title : string;
  @Input() data : IDonutChartData;
  @Input() colorScheme: any;

  ngOnInit(): void {
    if(!this.colorScheme) {
      this.colorScheme = { domain: CHART_DEFAULT_COLORS};
    }
  }
}
