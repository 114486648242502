import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ngx-gauge-chart',
  templateUrl: './ngx-gauge-chart.component.html',
  styleUrls: ['./ngx-gauge-chart.component.scss']
})
export class NgxGaugeChartComponent {
  @Input() value: any;
  @Input() label:any;
  @Input() type: string;
  @Input() thick:string;
  @Input() size: string;
  @Input() cap: string;
  @Input() max: string;
  @Input() gaugePrependText: string;
  @Input() gaugeAppendText: string;
  @Input() foregroundColor : any;
  @Input() backgroundColor : any;
}
