<div class="container">
  <div class="row">
    <div class="col-6" >
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
    <div class="col-6">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
  </div>  
  <div class="row">
    <div class="col-12">
      <ng-template appChart></ng-template>
    </div>
  </div> 
</div>
