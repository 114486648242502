<div class="app-score-card">
<ng-template #t let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill"><em class="fa fa-star"></em></span><em class="fa fa-star-o text-warning cursor"></em>
    </span>
  </ng-template>
  <span class = "d-flex">
  <ngb-rating [(rate)]="data" [starTemplate]="t" [readonly]="true" [max]="maxScore" title={{data}} ></ngb-rating>
  <nb-icon  icon="info-outline" *ngIf="scoreinfo" ngbTooltip="{{scoreinfotitle}}"></nb-icon>
   </span>
</div>