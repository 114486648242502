import { Component, Input } from '@angular/core';
import { IHorizontalStackedChartData } from '../chart-interfaces';

@Component({
  selector: 'app-horizontal-stacked-progress-chart',
  templateUrl: './horizontal-stacked-progress-chart.component.html',
  styleUrls: ['./horizontal-stacked-progress-chart.component.scss']
})
export class HorizontalStackedProgressChartComponent{
@Input() horizontalStackedChartData:IHorizontalStackedChartData;
}
