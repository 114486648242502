<div class="widget dark-chart-theme" #areacontainerRef>
  <div class="chart-heading">
    {{title}}
    <div class="detail-popup" *ngIf="data.componentId"  [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView(0)">
      <span class="storyPoints clickable" >
        <img src="assets/svg/code-commit-solid.svg" class="icon" ngbTooltip="Code Commit"/>
      </span>
  
    </div>
    <div class="detail-popup" *ngIf="data.componentId" [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView(1)">
      <span class="storyPoints clickable" >
        <img src="assets/svg/code-pull-request-solid.svg" class="icon" ngbTooltip="Pull Requests"/>
      </span>
    </div>
  
    <div class="detail-popup" *ngIf="data.componentId" [ngClass]="{'clickable': data?.clickableContent}" (click)="openDetailView(2)">
      <span class="storyPoints clickable" >
        <img src="assets/svg/users-solid.svg" class="icon" ngbTooltip="Top Contributors"/>
      </span>
    </div>
</div>
  <ng-container *ngIf="data.areaChart else elseBlock">
    <ngx-charts-area-chart
      [view]="[areacontainerRef.offsetWidth, 300]"  
      [scheme]="colorScheme"
      [results]="data.dataPoints"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [xAxisTickFormatting]="xAxisTickFormatting"
      [trimXAxisTicks]="trimXAxisTicks"
      [trimYAxisTicks]="trimYAxisTicks"
      [timeline]="timeline"
      [tooltipDisabled]="tooltipDisabled"
      [animations]='true'
      (select)="onSelect($event)">
    </ngx-charts-area-chart>
  </ng-container>
  <ng-template #elseBlock>
    <ngx-charts-line-chart
      [view]="[areacontainerRef.offsetWidth, 300]"        
      [scheme]="colorScheme"
      [results]="data.dataPoints"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [xAxisTickFormatting]="xAxisTickFormatting"
      [trimYAxisTicks]="trimYAxisTicks"
      [timeline]="timeline"
      [tooltipDisabled]="tooltipDisabled"
      [animations]='false'
      (select)="onSelect($event)">
    </ngx-charts-line-chart>
  </ng-template>


</div>
