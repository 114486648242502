import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmdbDataService {
  // private cmdbRoute = `/api/cmdb/setConfigItem`;
  appRoot = environment.appRoot;

   testConfigItemRoute = '';
   dashboardConfigItemListRoute = this.appRoot+'/api/cmdb/configItem';
   dashboardLinktemListRoute = this.appRoot+'/api/cmdb/link-dashboards';
   HygieiaConfig: any = { local: null };

  constructor(private http: HttpClient) { }

    getConfigItemList(type, params) {
        return this.http
        .get(this.HygieiaConfig.local ? this.testConfigItemRoute : this.dashboardConfigItemListRoute + '/' + type, {params});
    }

    getConfigItems(type, params) {
    return this.http.get(this.dashboardConfigItemListRoute + '/' + type, {params});
  }
  
  getAllConfigItems(itemType){
      return this.http.get<any>(`${this.dashboardConfigItemListRoute}/${itemType}`);
  }

  createCMDB(data: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type':  'application/json'})};
    return this.http.post(this.dashboardConfigItemListRoute, data, httpOptions);
  }

  linkTeamDashboard(data: any): Observable<any> {
    return this.http.post(this.dashboardLinktemListRoute, data);
  }
}
