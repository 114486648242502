import { Component, Input } from '@angular/core';
import { IPercentageData } from './percentage-chart-interface'

@Component({
  selector: 'app-percentage-chart',
  templateUrl: './percentage-chart.component.html',
  styleUrls: ['./percentage-chart.component.scss']
})
export class PercentageChartComponent {
  @Input() title: string;
  @Input() data:IPercentageData;
}
