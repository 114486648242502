<div class="vertical-bar-chart-ngx-container">
  <div class="header">
    <span class="title" *ngIf="title">{{title}}</span>
    <span class="sub-title" *ngIf="data?.subTitle">{{data.subTitle}}</span>
  </div>
  <ngx-charts-bar-vertical *ngIf="data && data.chartData.length else msg_block"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [scheme]="colorScheme || DEFAULT_CHART_COLORS"
    [results]="data.chartData"
    [gradient]="data.gradient || false"
    [xAxis]="data.showXAxis || true"
    [yAxis]="data.showYAxis || true"
    [legend]="data.showLegend"
    [legendTitle]="data.legendTitle || ''"
    [showXAxisLabel]="data.showXAxisLabel || true"
    [showYAxisLabel]="data.showYAxisLabel || true"
    [showGridLines]="data.showGridLines"
    [showDataLabel]="data.showDataLabel || false"
    [noBarWhenZero]="data.noBarWhenZero || false"
    [roundDomains]="data.roundDomains || false"
    [barPadding]="data.barPadding || 8"
    [wrapTicks]="data.wrapTicks || true"
    [yAxisTickFormatting]="data.yAxisTickFormatting"
    [xAxisTickFormatting]="data.xAxisTickFormatting"
    [dataLabelFormatting]="data.dataLabelFormatting"
    (select)="onSelect($event)"
    >
  </ngx-charts-bar-vertical>
  <ng-template #msg_block>
    <span class="msg_block">{{data?.loadingMessage}}</span>
  </ng-template>
</div>