<div  style="padding: 0px">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="this.detailData && this.detailData.url"><a href="{{this.detailData.url}}" target="_blank">{{title}}</a></h4>
      <h4 class="modal-title" *ngIf="!this.detailData || !this.detailData.url">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">{{message}}</div>
    <div class="modal-footer">

    </div>
  </div>
</div>
