<div class="container">
  <div class="row">
    <div class="col-md-12" >
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
    <div class="col-md-12">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
    <div class="col-md-12">
        <div class="chartParent" #chartParent>
          <ng-template appChart></ng-template>
        </div>
      </div>
      <!-- <div class="col-md-9">
        <div class="chartParent" #chartParent>
          <ng-template appChart></ng-template>
        </div> 
    </div> -->
  </div>
  <!-- <div class="row">
    <div class="col-md-10">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
    <div class="col-md-9">
      <div class="chartParent" #chartParent>
        <ng-template appChart></ng-template>
      </div>
    </div>
  </div> -->
</div>
