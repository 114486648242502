<div class="bar-chart">
    <div class="w-100 d-flex justify-content-between align-items-center bug-header">
        <span class="bug-title">{{horizontalStackedChartData?.title}}</span>
        <ul class="mb-0 ps-0 d-flex justify-content-between align-items-center bug-list">
          <li *ngFor="let barData of horizontalStackedChartData?.chartData"><div class="bug-legend-indicators" [ngStyle]="{'background-color': barData?.background}"></div>{{barData.name}}</li>
        </ul>
      </div>
      <span class="bug-sub-title" *ngIf="horizontalStackedChartData?.subtitle">{{horizontalStackedChartData?.subtitle}}</span>
      <div fxLayout="row" fxLayoutGap="0px" style="width: 100%">
        <div *ngFor="let barData of horizontalStackedChartData?.chartData;let i = index"
        [pTooltip]="barData?.tooltip" [tooltipPosition]="'above'"
         fxFlex='{{barData.percentage}}' class="single-bar"
         [class.left_borderRadius] = "i === 0"
         [ngStyle]="{'background-color': barData?.background}">
          {{barData.value}}
        </div>
        <p class="d-flex flex-column total-bug-title" *ngIf="horizontalStackedChartData?.total">
            {{horizontalStackedChartData?.total}} 
            <span>{{horizontalStackedChartData?.totalTitle}}</span>
        </p>
      </div>
</div>