<div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="col-form-label">{{message}}</div>
        <small class="text-muted">{{messageSubtext}}</small>
      </div>
      <div class="modal-footer">
        <div class="col text-center">
          <button class="btn btn-outline-secondary mr-3" (click)="activeModal.dismiss()">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="activeModal.close('close')">Delete</button>
        </div>
      </div>
    </div>
  </div>
