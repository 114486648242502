<div class="widget dark-chart-theme" #areacontainerRef *ngIf="this.severityBugsAPIResponse">
  <div class="chart-heading">
        {{title}}
  </div>
  <ngx-charts-bar-vertical-stacked
    [view]="[400, 300]"
    [scheme]="colorScheme"
    [results]="severityBugsAPIResponse"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showDataLabel]="true"
    [yAxisTicks]="yAxisTicks"
    [yAxisTickFormatting]="yAxisFormatter"
    (select)="onSelect($event)">
  </ngx-charts-bar-vertical-stacked>
</div>
