<div class="chips-view">
    <div class="header">
        <span class="title" *ngIf="title">{{title}}</span>
        <span class="sub-title" *ngIf="data?.subTitle">{{data.subTitle}}</span>
    </div>
    <div class="chips-container">
        <div class="chip" *ngFor="let item of data?.detailItems" [ngStyle]="{backgroundColor: item?.color || DEFAULT_CHIP_COLOR}"
        (click)="openDialog(item)">
            <span class="title" *ngIf="item?.title">{{item.title}}</span>
            <span class="detail" *ngIf="item?.detail">{{item.detail}}</span>
        </div>
    </div>
    <ng-template #msg_block>
        <span class="msg_block">{{data?.loadingMessage}}</span>
    </ng-template>
</div>