import { Component, OnInit } from '@angular/core';
import {BaseTemplateComponent} from '../../../shared/templates/base-template/base-template.component';
import {DashboardService} from '../../../shared/dashboard.service'

@Component({
  selector: 'app-capone-template',
  templateUrl: './capone-template.component.html',
  styleUrls: ['./capone-template.component.scss']
})
export class CaponeTemplateComponent extends BaseTemplateComponent implements OnInit {
  editedWidgets;
  pipelineWidget;
  featureWidget;

  scoreResult:any;
  scoreEnable:boolean = true;
  constructor( private dashboardService: DashboardService){
    super();
  }
  ngOnInit() {
    this.editedWidgets = this.widgets?.filter((item,i)=>{ return item?.title[0].toUpperCase() !== "PIPELINE" && item.title[0].toUpperCase() !== "FEATURE"});

   
    this.pipelineWidget = this.widgets?.filter((item,i)=>{ return item?.title[0].toUpperCase() === "PIPELINE"})
    this.featureWidget = this.widgets?.filter((item,i)=>{ return item?.title[0].toUpperCase() === "FEATURE"})
    if(this.featureWidget?.length==1 || this.pipelineWidget?.length==1){
      this.editedWidgets = [...this.editedWidgets, this.featureWidget[0]]
    }
    this.dashboardService.scoreCard.subscribe(res =>{
      if(res && res?.componentMetrics){
        this.scoreResult = res.componentMetrics;
        this.scoreEnable = true;
      }else if(res === null ){
        this.scoreResult = null;
        this.scoreEnable = false;
      }
    })
  }

  getScoreResult(title){
    if(this.scoreResult){
      let temp =  this.scoreResult.filter((item,i) => {
        if(title === "Code Repo"){
          return item.displayName ==  "SCM"
        }else if (title === "Security Analysis" || title === "Static Code Analysis"){
          return item.displayName ===  "Quality"
        }
          return item.displayName == title;
      });
      if(temp.length>0){
        if(title === "Security Analysis"){
          let result =   temp[0].children.filter((a,b)=>{
            return a.displayName == "SecurityViolations";
          });
          return result[0];
        }
        return temp[0];
      }
    }
    else{
      return null;
    }
  }
}
