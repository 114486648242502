import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {
@Input() data: any;
@Input() maxScore: any;
@Input() enableClick: boolean;
@Output() detail: EventEmitter<any> = new EventEmitter();
@Input() scoreinfo :boolean;
@Input() scoreinfotitle: any;
  constructor() { }

  ngOnInit(): void {
  }
  // scoredetails(){
  //   if(this.enableClick){
  //   this.detail.emit("'total'");
  // }
  // }
}
