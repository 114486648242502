<div class="table-fixed">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col">Repo</th>
                <th scope="col" *ngFor="let month of previousMonths">{{ month }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of repoData | slice:0:3">
                <td [style.color]="'blue'"> <ng-container *ngIf="data.shortName !== 'Total'; else totalName">
                    <span class="row-field">{{ data.name }}</span>
                </ng-container>
                <ng-template #totalName><b>{{ data.shortName }}</b></ng-template></td>
                <td>{{ data.secondLastMonthData }}</td>
                <td>{{ data.lastMonthData }}</td>
                <td>{{ data.currMonthData }}</td>
            </tr>
            <tr *ngFor="let data of repoData | slice:3" [style.display]="repoData.length > 3 ? 'table-row' : 'none'">
                <td> <ng-container *ngIf="data.shortName !== 'Total'; else totalName">
                    <span class="row-field">{{ data.name }}</span>
                </ng-container>
                <ng-template #totalName><b>{{ data.shortName }}</b></ng-template></td>
                <td>{{ data.secondLastMonthData }}</td>
                <td>{{ data.lastMonthData }}</td>
                <td>{{ data.currMonthData }}</td>
            </tr>
        </tbody>
    </table>
</div>

