import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "src/environments/environment";
@Injectable({
    providedIn: 'root'
})
export class CloudCostService {
    apiURL = environment.appRoot;
    private API_URL = this.apiURL + '/api';
    readonly COST_ENDPOINT = '/cloudcost';
    readonly MONTHLY_COST_ENDPOINT = '/cloudcost-monthly'
    readonly FETCH_SERVICES_ENDPOINT = '/cloudcost-service';
    readonly COST_GROUP_CONFIG_ENDPOINT = '/cloudcost-config'
    constructor(private http: HttpClient) {
    }
    fetchApplicationsCostData(payload: any): Observable<any> {
        const url = this.getURL(this.COST_ENDPOINT);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(url, payload, {headers});
    }
    
    fetchMonthlyKubeCost(payload:any):Observable<any>{
        const url = this.getURL(this.MONTHLY_COST_ENDPOINT);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(url, payload, {headers});
        
    }
    
    fetchCostGroupConfigData(): Observable<any> {
        const url = this.getURL(this.COST_GROUP_CONFIG_ENDPOINT);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get(url, {headers});
    }
    fetchServices(): Observable<any> {
        const url = this.getURL(this.FETCH_SERVICES_ENDPOINT);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(url, null, {headers});
    }
    getURL(endPoint): string {
        return this.API_URL + endPoint;
    }
}