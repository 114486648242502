import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectorService {
  appRoot = environment.appRoot;
  // URL for items by type
  private itemsByTypeRoute = this.appRoot+'/api/collector/item/type/';

  private itemsByTypeRouteBySearchField = this.appRoot+'/api/collector/item/type/searchField/';

  private itemsForAppDProjectNames = this.appRoot + '/api/productDashboards/se-metrics/appD/projects';
  // URL for items by id
  private itemRoute = this.appRoot+'/api/collector/item';

  private collectorsByTypeRoute = this.appRoot+'/api/collector/type/';

  constructor(private http: HttpClient) { }

  getItemsByType(type: string, params: any): Observable<any> {
    return this.http.get(this.itemsByTypeRoute + type, {params});
  }

  getItemsByTypeBySearchField(type: string, params: any): Observable<any> {
    return this.http.get(this.itemsByTypeRouteBySearchField + type, {params});
  }

  getItemsForAppDProjectNames( params: any, payload) : Observable<any>{
    return this.http.post(this.itemsForAppDProjectNames ,payload, {params} );
  }
  searchAppDItemsBySearchField( filter: string, payload): Observable<any> {
    return this.getItemsForAppDProjectNames( {search: filter, size: 20 }, payload);
  }

  searchItems(type: string, filter: string): Observable<any> {
    return this.getItemsByType(type, {search: filter, size: 30});
  }

  searchItemsBySearchField(type: string, filter: string, searchFieldInput: string): Observable<any> {
    return this.getItemsByTypeBySearchField(type, {search: filter, searchField: searchFieldInput, size: 20 });
  }

  getItemsById(id: string): Observable<any> {
    return this.http.get(this.itemRoute+"/" + id);
  }

  collectorsByType(collectorType): Observable<any> {
    return this.http.get(this.collectorsByTypeRoute + collectorType);
  }

  // getItemsByName(name: string): Observable<any> {
  //   return this.http.get(this.itemRoute + name);
  // }

  createorUpdateCollectorItem(data) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.put(this.itemRoute , data, httpOptions);
  }

}
